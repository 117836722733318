import axios from 'axios'
import {OptionModel} from '../models/OptionModel'

const API_URL = process.env.REACT_APP_API_URL || 'https://api.assempece.org.br/api/v1'

export const LIST_OPTIONS_URL = `${API_URL}/options`
export const OPTION_URL = `${API_URL}/option`

export function listOptions(queryParams: any) {
  return axios.post(LIST_OPTIONS_URL, queryParams)
}

export function getOption(uuid: string) {
  return axios.get(`${OPTION_URL}/${uuid}`)
}

export function createOption(option: OptionModel) {
  return axios.post(OPTION_URL, option)
}

export function updateOption(option: OptionModel) {
  return axios.put(`${OPTION_URL}/${option.uuid}`, option)
}

export function deleteOption(uuid: string) {
  return axios.delete(`${OPTION_URL}/${uuid}`)
}
