import { useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { TopicModel } from './models/TopicModel'
import * as actions from './redux/topicsActions'
import { TopicEditForm } from './TopicEditForm'

export interface TopicEditProps {
  topic: TopicModel | undefined
  parentUuid: string
  show: boolean
  handleClose: () => void
}

const initialValues = {
  uuid: undefined,
  name: '',
  number_order: undefined,
  status: '',
  is_multiple: false,
  standard_options: false,
  secret_vote: '',
}

export function TopicEditDialog(props: TopicEditProps) {
  const dispatch: any = useDispatch()
  const { topicForEdit } = useSelector(
    (state: any) => ({
      topicForEdit: state.topic.topicForEdit,
    }),
    shallowEqual
  )

  useEffect(() => {
    dispatch(actions.fetchTopic(props.topic?.uuid!))
  }, [props.topic, dispatch])

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>Pauta</Modal.Title>
        </Modal.Header>
        <TopicEditForm
          obj={props.topic ? topicForEdit : initialValues}
          parentUuid={props.parentUuid}
          handleClose={props.handleClose}
        />
      </Modal>
    </>
  )
}
