export let ElectionStatus = {
  ACTIVE: {text: 'Ativa', value: 'Ativa'},
  STARTED: {text: 'Iniciar Eleição', value: 'Iniciada'},
  CLOSED: {text: 'Encerrar votação', value: 'Encerrada'},
  POSTPONED: {text: 'Adiar Eleição', value: 'Adiada'},
  REACTIVE: {text: 'Reativar eleição', value: 'Ativa'},
}

export let initialStatus = [ElectionStatus.STARTED, ElectionStatus.CLOSED, ElectionStatus.POSTPONED]

export let afterStartStatus = [ElectionStatus.CLOSED, ElectionStatus.POSTPONED]

export let afterCloseStatus = [ElectionStatus.REACTIVE]
