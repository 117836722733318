import {UserModel} from '../models/UserModel'
import * as requestFromServer from './usersCrud'
import {callTypes, usersSlice} from './usersSlice'

const {actions} = usersSlice

export const setActualUser = (uuid: string) => async (dispatch: any) => {
  dispatch(actions.actualUser({uuid}))
}

export const fetchUsers = (queryParams: any) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.list}))
  return await requestFromServer
    .listUsers(queryParams)
    .then((response) => {
      const entities = response.data.data
      const totalCount = response.data.meta.total
      const lastPage = response.data.meta.last_page
      const firstPage = response.data.meta.first_page
      const curPage = response.data.meta.current_page
      dispatch(actions.usersFetched({entities, totalCount, lastPage, firstPage, curPage}))
    })
    .catch((error: any) => {
      error.clientMessage = "Can't find users"
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.list,
        })
      )
    })
}

export const fetchUser = (id: string | undefined) => async (dispatch: any) => {
  if (id !== undefined) {
    if (!id) {
      return dispatch(usersSlice.actions.userFetched({userForEdit: undefined}))
    }

    dispatch(usersSlice.actions.startCall({callType: callTypes.action}))
    return await requestFromServer
      .getUser(id)
      .then((response) => {
        const obj = response.data
        dispatch(actions.userFetched({userForEdit: obj}))
      })
      .catch((error) => {
        error.clientMessage = "Can't find user"
        dispatch(
          usersSlice.actions.catchError({
            error,
            callType: callTypes.action,
          })
        )
      })
  }
}

export const createUser = (userForCreation: UserModel) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .createUser(userForCreation)
    .then((response) => {
      const user = response.data
      dispatch(actions.userCreate({user}))
    })
    .catch((error) => {
      error.clientMessage = "Can't create comment"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}

export const updateUser = (user: UserModel) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .updateUser(user)
    .then(() => {
      dispatch(actions.userUpdate({user}))
    })
    .catch((error) => {
      error.clientMessage = "Can't update user"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}

export const deleteUser = (uuid: string) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .deleteUser(uuid)
    .then((response) => {
      dispatch(actions.userDelete({uuid}))
    })
    .catch((error) => {
      error.clientMessage = "Can't delete user"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}
export const changePasswordUser = (uuid: string, password: string) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .changePasswordUser(uuid, password)
    .then((response) => {
      dispatch(actions.changePassword({uuid, password}))
    })
    .catch((error) => {
      error.clientMessage = "Can't update password"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}
/*


export const deleteComments = ids => dispatch => {
  dispatch(commentsSlice.actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteComments(ids)
    .then(() => {
      dispatch(commentsSlice.actions.commentsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete comments";
      dispatch(
        commentsSlice.actions.catchError({ error, callType: callTypes.action })
      );
    });
}; */
