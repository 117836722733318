import React from 'react'

type PaginationProps = {
    firstPage: number
    lastPage: number
    curPage: number
    page: number
    setPage: (e: any) => void
}

const Pagination = ({ firstPage, lastPage, curPage, page, setPage }: PaginationProps) => {
    const createPagination = (firstPage: number, lastPage: number) => {
        let array: number[] = []
        let start = firstPage
        let end = 5
        if (firstPage === lastPage) {
            start = firstPage
            end = lastPage
        } else {
            if (curPage >= 3) {
                start = curPage - 2
                end = curPage + 2
            }
            if (end > lastPage) {
                end = lastPage
                start = lastPage - 4
            }
        }
        for (let i = start; i <= end; i++) {
            array.push(i)
        }
        return array
    }

    const goToLastPage = () => {
        if (curPage-- >= firstPage) {
            setPage(curPage--)
        }
    }

    const goToNextPage = () => {
        if (curPage++ < lastPage) {
            setPage(curPage++)
        }
    }

    let array = createPagination(firstPage, lastPage)

    const onPaginationClick = (event: any) => {
        setPage(event.target.outerText)
    }

    return (
        <ul className='pagination'>
            {array && array.length > 1 ? (
                <>
                    {
                        curPage !== firstPage ? (
                            <li className='page-item previous'>
                                <button onClick={goToLastPage} className='page-link'>
                                    <i className='previous'></i>
                                </button>
                            </li>
                        ) : null}
                    {array.map((n: number) => (
                        <li className={`page-item ${n === curPage ? 'active' : ''}`}>
                            <button onClick={onPaginationClick} className='page-link'>
                                {n}
                            </button>
                        </li>
                    ))}
                    {
                        curPage !== lastPage ? (
                            <li className='page-item next'>
                                <button onClick={goToNextPage} className='page-link'>
                                    <i className='next'></i>
                                </button>
                            </li>
                        ) : null
                    }
                </>
            ) : null}
        </ul>
    )
}

export default Pagination