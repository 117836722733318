import {createSlice} from '@reduxjs/toolkit'
import {TopicReduxModel} from '../models/TopicReduxModel'

const initialTopicState: TopicReduxModel = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  curPage: 1,
  firstPage: 1,
  lastPage: 1,
  entities: [],
  topicStart: undefined,
  topicForEdit: undefined,
  lastError: null,
  type: 'list',
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const topicsSlice = createSlice({
  name: 'topics',
  initialState: initialTopicState,
  reducers: {
    catchError: (state, action) => {
      state.topicStart = undefined
      state.lastError = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.lastError = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // type view
    topicsType: (state, action) => {
      const {type} = action.payload
      state.listLoading = false
      state.lastError = null
      state.topicForEdit = undefined
      state.type = type
    },
    // gettopicById
    topicFetched: (state, action) => {
      state.actionsLoading = false
      state.topicForEdit = action.payload.topicForEdit
      state.lastError = null
    },
    // findtopics
    topicsFetched: (state, action) => {
      const {totalCount, entities, lastPage, firstPage, curPage} = action.payload
      state.listLoading = false
      state.actionsLoading = false
      state.firstPage = firstPage
      state.lastPage = lastPage
      state.curPage = curPage
      state.lastError = null
      state.entities = entities
      state.totalCount = totalCount
    },
    // findtopics
    topicsOpenOrStartFetch: (state, action) => {
      const {entity} = action.payload
      state.listLoading = false
      state.actionsLoading = false
      state.lastError = null
      state.topicStart = entity
    },
    // create topic
    topicCreate: (state, action) => {
      state.actionsLoading = false
      state.lastError = null
      state.entities = [action.payload.topic].concat(...state.entities)
    },
    // update topic
    topicUpdate: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
      state.entities = state.entities.map((entity) => {
        if (entity.uuid === action.payload.topic.uuid) {
          return action.payload.topic
        }
        return entity
      })
    },
    //change status
    topicStatusUpdate: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
      state.entities = state.entities.map((entity) => {
        if (entity.uuid === action.payload.topic.uuid) {
          return action.payload.topic
        }
        return entity
      })
    },
    // delete topic
    topicDelete: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
      state.entities = state.entities.filter((el) => el.uuid !== action.payload.uuid)
    },
  },
})
