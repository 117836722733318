import axios from 'axios'
import {CreateVoteModel} from '../models/CreateVoteModel'
import {VoteModel} from '../models/VoteModel'

const API_URL = process.env.REACT_APP_API_URL || 'https://api.assempece.org.br/api/v1'

export const LIST_VOTES_URL = `${API_URL}/votes`
export const VOTE_URL = `${API_URL}/vote/simpleVote`
export const VOTE_MULTIPLE_URL = `${API_URL}/vote/multipleVotes`

export function getVote(uuid: string) {
  return axios.get(`${VOTE_URL}/${uuid}`)
}

export function createVote(vote: CreateVoteModel) {
  return axios.post(VOTE_URL, vote)
}

export function createMultipleVote(votes: CreateVoteModel[]) {
  return axios.post(VOTE_MULTIPLE_URL, {votes})
}

export function deleteVote(uuid: string) {
  return axios.delete(`${VOTE_URL}/${uuid}`)
}
