import React from 'react'
import { TopicStatus } from '../../../utils/TopicStatus'
import { TopicModel } from '../../topics/models/TopicModel'
import { VoteModel } from '../models/VoteModel'
import SimpleVoteTopic from './SimpleVoteTopic'

type WrapperProps = {
    startedTopic: TopicModel | undefined
    myVote: VoteModel[]
    createVote: () => void
    myChoice: any
    setMyChoice: (item: any) => void
}

const SimpleVoteWrapper = (props: WrapperProps) => {
    return (
        <>
            {props.startedTopic?.status === TopicStatus.OPENED.value ? (
                <>
                    <div className='text-gray-700 fs-1 pt-2 align-center text-center'>
                        <span>Pauta em discussão: </span>
                        <span className="fw-bolder text-hover-primary">{props.startedTopic.name}</span>
                    </div>
                    <div className='separator separator-dashed border-gray-300 border-1'></div>
                    <div className='text-gray-700 text-hover-primary fw-bolder fs-2 pt-2 align-center text-center'>
                        Opções
                    </div>
                    <div className='text-primary fw-bolder fs-2 pt-2 align-center text-center'>
                        {props.startedTopic.options.map((item: any) => (
                            <p>
                                {item.name}
                            </p>
                        ))}
                    </div>
                </>
            ) : (
                props.startedTopic?.status === TopicStatus.STARTED.value ? (
                    <>
                        <div className='text-gray-700 text-hover-primary fs-1 pt-2 align-center text-center'>
                            <span>Pauta em votação: </span>
                            <span className="fw-bolder">{props.startedTopic.name}</span>
                        </div>
                        <div className='separator separator-dashed border-gray-300 border-1 mt-3'></div>
                        {props.myVote.length === 0 ?
                            (
                                <SimpleVoteTopic startedTopic={props.startedTopic} createVote={props.createVote} myChoice={props.myChoice} setMyChoice={props.setMyChoice} />
                            ) : (
                                <div className="text-center">
                                    <p style={{ fontSize: 20, marginBottom: 10, marginTop: 10 }}>
                                        Seu Voto foi computado com sucesso!
                                    </p>
                                    <p style={{ fontSize: 20, marginBottom: 10 }}>
                                        Voto computado: <span className="fw-bolder">{props.myVote[0].option.name.toUpperCase()}</span>
                                    </p>
                                    <p style={{ fontSize: 20, marginBottom: 10 }}>
                                        Favor aguardar o resultado.
                                    </p>
                                </div>
                            )}
                    </>
                ) : (
                    <p className="text-center fs-1 my-10">
                        Ainda não há pauta iniciada para discussão!
                    </p>
                )
            )}
        </>
    )
}

export default SimpleVoteWrapper
