import React from 'react'
import { SessionStatus } from '../../../utils/SessionStatus'
import { TopicStatus } from '../../../utils/TopicStatus'
import { TopicModel } from '../../topics/models/TopicModel'
import { VoteModel } from '../models/VoteModel'
import MultipleVoteTopic from './MultipleVoteTopic'

type WrapperProps = {
    startedTopic: TopicModel | undefined
    myVote: VoteModel[]
    createVote: (item: any[]) => void
    myChoice: any
    setMyChoice: (item: any) => void
}

const MultipleVoteWrapper = (props: WrapperProps) => {

    return (
        <>
            {props.startedTopic?.status === TopicStatus.OPENED.value ? (
                <>
                    <div className='text-gray-700 text-hover-primary fs-1 pt-2 align-center text-center'>
                        <span>Pauta em discussão: </span>
                        <span className="fw-bolder">{props.startedTopic.name}</span>
                    </div>
                    <div className='separator separator-dashed border-gray-300 border-1'></div>
                    <div className='text-gray-700 text-hover-primary fw-bolder fs-2 pt-2 align-center text-center'>
                        Opções
                    </div>
                    <div className='text-primary fw-bolder fs-2 pt-2 align-center text-center'>
                        {props.startedTopic.options.map((item: any) => (
                            <p>
                                {item.name}
                            </p>
                        ))}
                    </div>
                </>
            ) : (
                props.startedTopic?.status === SessionStatus.STARTED.value ? (
                    <>
                        <div className='text-gray-700 text-hover-primary fs-1 pt-2 align-center text-center'>
                            <span>Pauta em votação: </span>
                            <span className="fw-bolder">{props.startedTopic.name}</span>
                        </div>
                        {props.myVote.length === 0 ?
                            (
                                <MultipleVoteTopic maxChoice={props.startedTopic.max_vote!} minChoice={props.startedTopic.min_vote!} options={props.startedTopic.options} createMultipleVote={props.createVote} myVote={props.myVote} />
                            ) : (
                                <div className='text-center'>
                                    <p style={{ fontSize: 20, marginBottom: 10, marginTop: 10 }}>
                                        Seu Voto foi computado com sucesso!
                                    </p>
                                    <p style={{ fontSize: 20, marginBottom: 10 }}>
                                        Voto(s) computado:
                                    </p>
                                    {props.myVote.map((item) => (
                                        <p style={{ fontSize: 20, marginBottom: 10 }}>
                                            <span style={{ fontWeight: 'bold' }}>{item.option.name.toUpperCase()}</span>
                                        </p>
                                    ))}
                                    <p style={{ fontSize: 20, marginBottom: 10 }}>
                                        Favor aguardar o resultado.
                                    </p>
                                </div>
                            )}
                    </>
                ) : (
                    <div className='text-gray-700 text-hover-primary fs-1 pt-2 align-center text-center'>
                        <span>Ainda não há pauta iniciada para discussão!</span>
                    </div>
                )
            )}
        </>
    )
}

export default MultipleVoteWrapper
