import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useDispatch } from 'react-redux'
import { ElectionModel } from '../elections/models/ElectionModel'
import { TopicModel } from '../topics/models/TopicModel'
import { OptionModel } from './models/OptionModel'
import * as server from './redux/optionsCrud'

export interface OptionsProps {
  topicId: any
  topic: TopicModel | ElectionModel | undefined
  show: boolean
  handleClose: () => void
}

const initialOption: OptionModel = {
  uuid: null,
  parentUuid: '',
  name: '',
  number_order: 0,
}

export function OptionsDialog(props: OptionsProps) {
  const [newChoice, setNewChoice] = useState({ name: '' })
  const [choices, setChoices] = useState<any>([])

  const getOptions = async () => {
    await server.listOptions({ page: 1, name: '', parentUuid: props.topicId }).then((response) => {
      setChoices(response.data.data)
    })
  }

  useEffect(() => {
    setNewChoice({ name: '' })
    if (props.topicId) {
      getOptions()
    }
  }, [props])

  const editChoices = (e: any, i: number) => {
    setChoices(
      choices.map((el: any, index: number) => (index === i ? Object.assign({}, el, { name: e }) : el))
    )
  }

  const deleteChoiceInTopic = async (i: number) => {
    let obj = choices.filter((item: any, j: number) => i === j)[0]
    setChoices(choices.filter((item: any, j: number) => i !== j))
    await server.deleteOption(obj.uuid).then((response) => {
    })
  }

  const addChoiceInTopic = async () => {
    let copy = Object.assign({}, initialOption)
    copy.name = newChoice.name
    copy.parentUuid = props.topic?.uuid!
    await server.createOption(copy).then((response) => {
      setChoices([...choices].concat(response.data))
      setNewChoice({ name: '' })
    })
  }

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header >
          <Modal.Title>Gerenciamento de opções</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {choices
            ? choices.map((item: any, index: number) => (
              <>
                <div style={{ display: 'flex' }} className='my-2' key={item.uuid}>
                  <input
                    type='text'
                    disabled
                    value={item.name}
                    onChange={(e) => editChoices(e.target.value, index)}
                    className='form-control form-control-solid'
                    style={{ flex: 6 }}
                  />
                  {props.topic?.status === 'Ativa' ||
                    props.topic?.status === 'Aberta para discussão' ? (
                    <button
                      onClick={() => deleteChoiceInTopic(index)}
                      className='btn btn-icon btn-danger mx-2'
                      style={{ flex: 1 }}
                    >
                      <i className='fas fa-trash fs-4'></i>
                    </button>
                  ) : null}
                </div>
              </>
            ))
            : null}
          {props.topic?.status === 'Ativa' || props.topic?.status === 'Aberta para discussão' ? (
            <>
              <div style={{ display: 'flex' }} className='my-2' key={props.topicId}>
                <input
                  type='text'
                  value={newChoice.name}
                  placeholder='Adicione uma nova opção'
                  onChange={(e) => setNewChoice({ ...newChoice, name: e.target.value })}
                  className='form-control form-control-solid'
                  style={{ flex: 6 }}
                />
                <button
                  onClick={addChoiceInTopic}
                  className='btn btn-icon btn-primary mx-2'
                  style={{ flex: 1 }}
                >
                  <i className='fas fa-check fs-4'></i>
                </button>
              </div>
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-light' onClick={props.handleClose}>
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
