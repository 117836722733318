export const formatterCPF = (cpf: string) => {
  if (!cpf) {
    return '-'
  }
  cpf = cpf.replace(/[^\d]/g, '')

  //realizar a formatação...
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const formatterIP = (ip: string) => {
  if (!ip) {
    return '-'
  }
  ip = ip.replace(/[^\d]/g, '')

  //realizar a formatação...
  return ip.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, '$1.$2.$3.$4')
}
