import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router";
import socketIOClient from "socket.io-client";
import { RootState } from "../../../setup";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { TopicStatus } from "../../utils/TopicStatus";
import { SessionModel } from "../sessions/models/SessionModel";
import * as serverSessionCRUD from "../sessions/redux/sessionsCrud";
import { TopicModel } from "../topics/models/TopicModel";
import { UserModel } from "../users/models/UserModel";
import * as serverVoteCRUD from "./api/votesCrud";
import MultipleVoteWrapper from "./components/MultipleVoteWrapper";
import SimpleVoteWrapper from "./components/SimpleVoteWrapper";
import { CreateVoteModel } from "./models/CreateVoteModel";
import { VoteModel } from "./models/VoteModel";
const ENDPOINT = process.env.SERVER_API_URL || "https://api.assempece.org.br";

const VoteTopic = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const [startedSession, setStartedSession] = useState<SessionModel>();
  const [startedTopic, setStartedTopic] = useState<TopicModel>();
  const [myChoice, setMyChoice] = useState<any>();
  const [myVote, setMyVote] = useState<VoteModel[]>([]);
  const history = useHistory();

  const applyGeneralFilter = async () => {
    const filterSession = { page: 1, name: "", status: "Iniciada" };
    await serverSessionCRUD.listSessions(filterSession).then((response) => {
      const first: SessionModel = response.data.data[0];
      let availableTopic = first.topics.filter(
        (item: TopicModel) =>
          item?.status === TopicStatus.OPENED.value ||
          item?.status === TopicStatus.STARTED.value
      );
      if (availableTopic.length > 0) {
        setStartedTopic(availableTopic[0]);
      }
      setStartedSession(first);
      const checkVote: VoteModel[] = availableTopic[0]?.votes.filter(
        (item: VoteModel) => item.user.uuid === user.uuid
      );
      setMyVote(checkVote);
    });
  };

  const buttonByStatus = (status: string) => {
    switch (status) {
      case TopicStatus.ACTIVE.value:
        return "success";

      case TopicStatus.OPENED.value:
        return "primary";

      case TopicStatus.STARTED.value:
        return "primary";

      default:
        return "warning";
    }
  };

  const getTopicResult = (e: TopicModel) => {
    if (e?.votes.length === 0) {
      return "Sem votação";
    }
    let result: { qnt: number; option: any }[] = [];
    if (e.options) {
      e.options.forEach((o: any) => {
        result.push({
          qnt: e?.votes.filter((v) => v.option?.uuid === o.uuid).length,
          option: o,
        });
      });
      result.sort((a, b) => (b.qnt > a.qnt) ? 1 : ((a.qnt > b.qnt) ? -1 : 0))
      if (result[0].qnt === result[1].qnt) {
        return "Empate"
      }
      return result[0].option.name;
    }
    return "Sem votação";
  };

  const createVote = async () => {
    const obj: CreateVoteModel = {
      optionUuid: myChoice.uuid,
      topicUuid: startedTopic?.uuid!,
      userUuid: user.uuid!,
    };
    await serverVoteCRUD.createVote(obj).then((response) => {
      applyGeneralFilter();
    });
  };

  const createMultipleVote = async (arrayChoices: any[]) => {
    const votes: CreateVoteModel[] = [];
    arrayChoices?.forEach((element) => {
      const obj: CreateVoteModel = {
        optionUuid: element.uuid,
        topicUuid: startedTopic?.uuid!,
        userUuid: user.uuid!,
      };
      votes.push(obj);
    });
    await serverVoteCRUD.createMultipleVote(votes).then((response) => {
      applyGeneralFilter();
    });
  };

  const [response, setResponse] = useState();

  function msToTime(data: string | undefined) {
    if (data) {
      let duration = Number(data);
      var milliseconds = Math.floor((duration % 1000) / 100);
      var seconds = Math.floor((duration / 1000) % 60);
      var minutes = Math.floor((duration / (1000 * 60)) % 60);
      var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      let hoursStr = hours < 10 ? "0" + hours : hours;
      let minutesStr = minutes < 10 ? "0" + minutes : minutes;
      let secondsStr = seconds < 10 ? "0" + seconds : seconds;

      return hoursStr + ":" + minutesStr + ":" + secondsStr;
    }
    return "Pauta iniciada";
  }

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("index:started", (data) => {
      if (data && data.topics) {
        let availableTopic = data.topics.filter(
          (item: TopicModel) =>
            item?.status === TopicStatus.OPENED.value ||
            item?.status === TopicStatus.STARTED.value
        );
        if (availableTopic.length > 0) {
          setStartedTopic(availableTopic[0]);
        } else {
          setStartedTopic(undefined);
        }
        const checkVote: VoteModel[] = availableTopic[0]?.votes.filter(
          (item: VoteModel) => item.user.uuid === user.uuid
        );
        if (checkVote) {
          setMyVote(checkVote);
        }
      } else {
        setStartedTopic(undefined);
        setMyVote([]);
      }
      setStartedSession(data);
    });
    socket.on("FromAPI", (data) => {
      setResponse(data);
      msToTime(data);
    });
    socket.on("index:topicFinished", (data) => {
      if (!data) {
        setStartedTopic(undefined);
        setMyVote([]);
      }
    });
    socket.on("index:finished", (data) => {
      if (!data) {
        setStartedSession(undefined);
        history.push("/dashboard");
      }
    });
  }, []);

  return (
    <>
      <div className="card card-xl-stretch mb-xl-12">
        <div className='me-5 me-md-10 pt-5 flex-grow-1 flex-lg-grow-0 text-center'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logo-assempece.png')}
            className='logo-default h-150px'
            style={{ maxWidth: document.body.clientWidth - 40 }}
          />
        </div>
        <div className="separator my-5"></div>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-1 mt-1">
              <KTSVG
                path="/media/icons/duotune/general/gen025.svg"
                className="svg-icon svg-icon-4x svg-icon-primary"
              />
            </div>
            <div className="flex-grow-1">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3">Sessão Atual</span>
                <span className="text-muted fw-bold fs-3">
                  {startedSession?.name}
                </span>
              </h3>
            </div>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-light-secondary"
              onClick={() => history.push('/dashboard')}
            >
              Voltar
            </button>
          </div>
        </div>
        {/* begin::Body */}
        <div className="card-body py-3">
          <div className="align-self-center">
            {startedTopic &&
              startedTopic.status === TopicStatus.STARTED.value ? (
              <div className="w-100 text-center">
                <span className="badge badge-light-primary fw-bolder fs-1 pt-4 align-center text-center">
                  {msToTime(response)}
                </span>
              </div>
            ) : null}
            <div className="text-gray-700 fw-bolder text-hover-primary fs-1 pt-2 align-center text-center">
              Lista de pautas
            </div>
            <div className="separator separator-dashed border-gray-300 border-1 mt-3"></div>
            <div className="">
              <div className="table-responsive">
                <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                  <thead className="border-gray-300">
                    <tr className="fw-bolder text-muted border-gray-400">
                      <th className="col-5">Assunto</th>
                      <th className="col-1">Resultado</th>
                      <th className="col-1">Voto Secreto</th>
                      <th className="col-1">Status</th>
                    </tr>
                  </thead>
                  <tbody className="border-gray-300">
                    {startedSession?.topics!.map((item: TopicModel) => (
                      <tr>
                        <td className="text-dark fw-bolder text-hover-primary fs-6">
                          {item?.name}
                        </td>
                        <td className="text-dark fw-bolder text-hover-primary fs-6">
                          {getTopicResult(item)}
                        </td>
                        <td className="text-dark fw-bolder text-hover-primary fs-6">
                          {item?.secret_vote}
                        </td>
                        <td className="text-dark fw-bolder text-hover-primary fs-6">
                          <span
                            className={`badge badge-light-${buttonByStatus(
                              item?.status ? item?.status : ""
                            )}`}
                          >
                            {item?.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="separator separator-dashed border-gray-300 border-1"></div>
              {!Boolean(startedTopic?.is_multiple) ? (
                <SimpleVoteWrapper
                  myVote={myVote}
                  startedTopic={startedTopic}
                  createVote={createVote}
                  myChoice={myChoice}
                  setMyChoice={setMyChoice}
                />
              ) : (
                <MultipleVoteWrapper
                  myVote={myVote}
                  startedTopic={startedTopic}
                  createVote={createMultipleVote}
                  myChoice={myChoice}
                  setMyChoice={setMyChoice}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* end::Body */}
    </>
  );
};

export { VoteTopic };
