import React, { useState } from 'react';

type MultipleVoteProps = {
    options: any[] | undefined
    myVote: any[]
    minChoice: number | undefined
    maxChoice: number | undefined
    createMultipleVote: ([]) => void
}

const MultipleVote = (props: MultipleVoteProps) => {
    const [checkedState, setCheckedState] = useState(
        new Array(props.options ? props.options.length : 0).fill(false)
    );

    const handleOnChange = (position: number) => {
        if (checkedState.filter(item => item).length < (props.maxChoice != null ? props.maxChoice : 0) || checkedState[position]) {
            const updatedCheckedState = checkedState.map((item, index) =>
                index === position ? !item : item
            );
            setCheckedState(updatedCheckedState);
        }
    };

    const vote = () => {
        const myChoices = props.options?.filter((item, index) => checkedState[index])
        props.createMultipleVote(myChoices!)
    }

    return (
        < div style={{ marginTop: 20 }}>
            {
                props.myVote && props.myVote.length > 0 ?
                    (
                        <div className='text-center'>
                            <p style={{ fontSize: 20, marginBottom: 10, marginTop: 10 }}>
                                Seu Voto foi computado com sucesso!
                            </p>
                            <p style={{ fontSize: 20, marginBottom: 10 }}>
                                Voto(s) computado:
                            </p>
                            {props.myVote.map((item) => (
                                <p style={{ fontSize: 20, marginBottom: 10 }}>
                                    <span style={{ fontWeight: 'bold' }}>{item.option.name.toUpperCase()}</span>
                                </p>
                            ))}
                            <p style={{ fontSize: 20, marginBottom: 10 }}>
                                Favor aguardar o resultado.
                            </p>
                        </div>
                    ) : (
                        <>
                            <div className='text-center'>
                                <p style={{ fontSize: 20, color: 'red', marginBottom: 20 }}>
                                    <span>O mínimo de escolhas é:  {props.minChoice}</span>
                                </p>
                                <p style={{ fontSize: 20, color: 'red', marginBottom: 20 }}>
                                    <span>O máximo de escolhas é:  {props.maxChoice}</span>
                                </p>
                            </div>
                            < div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {
                                    props.options?.map(({ name, uuid }, index) => {
                                        return (
                                            <div style={{ marginLeft: 10, marginTop: 20, minWidth: 300 }} key={index}>
                                                <div className="form-check form-check-custom form-check-solid form-check-lg">
                                                    <input className="form-check-input" type="checkbox" value={uuid} id={`custom-checkbox-${index}`} checked={checkedState[index]}
                                                        onChange={() => handleOnChange(index)} />
                                                    <label className="form-check-label" style={{ fontSize: 20 }} htmlFor={`custom-checkbox-${index}`}>{name}
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div >
                            {
                                props.minChoice != null && checkedState.filter(item => item).length >= props.minChoice ?
                                    (
                                        <div style={{ marginTop: 30 }}>
                                            <button
                                                type='button'
                                                onClick={vote}
                                                className='btn btn-primary float-right mt-2 mx-2'
                                            >
                                                Confirmar
                                            </button>
                                        </div>
                                    ) : null
                            }
                        </>
                    )
            }
        </div >
    )
}

export default MultipleVote
