import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { useHistory } from "react-router";
import { RootState } from "../../../setup";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { ElectionModel } from "../elections/models/ElectionModel";
import * as serverElectionCRUD from "../elections/redux/electionsCrud";
import { UserModel } from "../users/models/UserModel";
import * as serverVoteCRUD from "./api/votesCrud";
import MultipleVote from "./components/MultipleVote";
import SimpleVote from "./components/SimpleVote";
import { CreateVoteModel } from "./models/CreateVoteModel";
import { VoteModel } from "./models/VoteModel";
import { ElectionStatus } from "../../utils/ElectionStatus";

const ENDPOINT = process.env.SERVER_API_URL || "https://api.assempece.org.br";

const VoteElection = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const [startedElection, setStartedElection] = useState<ElectionModel>();
  const [myChoice, setMyChoice] = useState<any>();
  const [myVote, setMyVote] = useState<VoteModel[]>([]);
  const history = useHistory();

  const applyGeneralFilter = async () => {
    await serverElectionCRUD.electionStart().then((response) => {
      const first: ElectionModel = response.data;
      const checkVote: VoteModel[] = first.votes.filter(
        (item: VoteModel) => item.user.uuid === user.uuid
      );
      setMyVote(checkVote);
      setStartedElection(first);
    });
  };

  const createVote = async () => {
    const obj: CreateVoteModel = {
      optionUuid: myChoice.uuid,
      topicUuid: startedElection?.uuid!,
      userUuid: user.uuid!,
    };
    await serverVoteCRUD.createVote(obj).then((response) => {
      applyGeneralFilter();
    });
  };

  const createMultipleVote = async (arrayChoices: any[]) => {
    const votes: CreateVoteModel[] = [];
    arrayChoices?.forEach((element) => {
      const obj: CreateVoteModel = {
        optionUuid: element.uuid,
        topicUuid: startedElection?.uuid!,
        userUuid: user.uuid!,
      };
      votes.push(obj);
    });
    await serverVoteCRUD.createMultipleVote(votes).then((response) => {
      applyGeneralFilter();
    });
  };

  const [response, setResponse] = useState();

  function msToTime(data: string | undefined) {
    if (data) {
      let duration = Number(data);
      var milliseconds = Math.floor((duration % 1000) / 100);
      var seconds = Math.floor((duration / 1000) % 60);
      var minutes = Math.floor((duration / (1000 * 60)) % 60);
      var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      let hoursStr = hours < 10 ? "0" + hours : hours;
      let minutesStr = minutes < 10 ? "0" + minutes : minutes;
      let secondsStr = seconds < 10 ? "0" + seconds : seconds;

      return hoursStr + ":" + minutesStr + ":" + secondsStr;
    }
    return "Eleição iniciada";
  }

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("index:electionStarted", (data) => {
      if (data) {
        setStartedElection(data);
        const checkVote: VoteModel[] = data?.votes.filter(
          (item: VoteModel) => item.user.uuid === user.uuid
        );
        if (checkVote) {
          setMyVote(checkVote);
        }
      } else {
        setStartedElection(undefined);
        setMyVote([]);
      }
    });
    socket.on("FromElectionAPI", (data) => {
      setResponse(data);
      msToTime(data);
    });
    socket.on("index:electionFinished", (data) => {
      if (!data) {
        setStartedElection(undefined);
        setMyVote([]);
        history.push("/dashboard");
      }
    });
  }, []);

  return (
    <>
      <div className="card card-xl-stretch mb-xl-12">
        <div className='me-5 me-md-10 pt-5 flex-grow-1 flex-lg-grow-0 text-center'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logo-assempece.png')}
            className='logo-default h-150px'
            style={{ maxWidth: document.body.clientWidth - 40 }}
          />
        </div>
        <div className="separator my-5"></div>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-1 mt-1">
              <KTSVG
                path="/media/icons/duotune/general/gen025.svg"
                className="svg-icon svg-icon-4x svg-icon-primary"
              />
            </div>
            <div className="flex-grow-1">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3">Eleição</span>
                <span className="text-muted fw-bold fs-3">
                  {startedElection?.name}
                </span>
              </h3>
            </div>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-light-secondary"
              onClick={() => history.push('/dashboard')}
            >
              Voltar
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          <div className="card-header border-0 py-5 bg-opacity-15 justify-content-center text-center">
            <div className="align-self-center">
              {startedElection &&
                startedElection.status === ElectionStatus.STARTED.value ? (
                <div>
                  <span className="badge badge-light-primary fw-bolder fs-1 pt-4 align-center text-center">
                    {msToTime(response)}
                  </span>
                </div>
              ) : null}
              <div className="text-gray-700 fw-bolder text-hover-primary fs-1 pt-2 align-center text-center">
                Opções
              </div>
              <div className="separator separator-dashed border-gray-300 border-1 mt-5"></div>
              {!Boolean(startedElection?.is_multiple) ? (
                <div className="align-self-center flex-row justify-align-content-around">
                  {myVote.length === 0 ? (
                    <SimpleVote
                      myChoice={myChoice}
                      setMyChoice={setMyChoice}
                      createVote={createVote}
                      startedElection={startedElection!}
                      key={startedElection?.uuid}
                    />
                  ) : (
                    <div className='text-center'>
                      <p
                        style={{
                          fontSize: 20,
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                      >
                        Seu Voto foi computado com sucesso!
                      </p>
                      <p style={{ fontSize: 20, marginBottom: 10 }}>
                        Voto computado:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {myVote[0].option.name.toUpperCase()}
                        </span>
                      </p>
                      <p style={{ fontSize: 20, marginBottom: 10 }}>
                        Favor aguardar o resultado.
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <MultipleVote
                  myVote={myVote}
                  createMultipleVote={createMultipleVote}
                  options={startedElection?.options}
                  minChoice={startedElection?.min_vote}
                  maxChoice={startedElection?.max_vote}
                />
              )}
            </div>
          </div>
        </div>
        {/* end::Body */}
      </div>
    </>
  );
};

export { VoteElection };
