/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { SessionCard } from '../modules/sessions/SessionCard'

const SessionPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-12'>
      <div className='col-xxl-12'>
        <SessionCard />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const SessionWrapper: FC = () => {
  return (
    <>
      <SessionPage />
    </>
  )
}

export { SessionWrapper }

