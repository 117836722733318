import {createSlice} from '@reduxjs/toolkit'
import {ElectionReduxModel} from '../models/ElectionReduxModel'

const initialElectionState: ElectionReduxModel = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  curPage: 1,
  firstPage: 1,
  lastPage: 1,
  entities: [],
  entityStart: undefined,
  electionForEdit: undefined,
  lastError: null,
  type: 'list',
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const electionsSlice = createSlice({
  name: 'elections',
  initialState: initialElectionState,
  reducers: {
    catchError: (state, action) => {
      state.entityStart = undefined
      state.lastError = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.lastError = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // type view
    electionsType: (state, action) => {
      const {type} = action.payload
      state.listLoading = false
      state.lastError = null
      state.electionForEdit = undefined
      state.type = type
    },
    // getelectionById
    electionFetched: (state, action) => {
      state.actionsLoading = false
      state.electionForEdit = action.payload.electionForEdit
      state.lastError = null
    },
    // findelections
    electionsFetched: (state, action) => {
      const {totalCount, entities, lastPage, firstPage, curPage} = action.payload
      state.listLoading = false
      state.actionsLoading = false
      state.firstPage = firstPage
      state.lastPage = lastPage
      state.curPage = curPage
      state.lastError = null
      state.entities = entities
      state.totalCount = totalCount
    },
    // findelectionstart
    electionStart: (state, action) => {
      const {entityStart} = action.payload
      state.listLoading = false
      state.actionsLoading = false
      state.lastError = null
      state.entityStart = entityStart
    },
    // create election
    electionCreate: (state, action) => {
      state.actionsLoading = false
      state.lastError = null
      state.entities = [action.payload.election].concat(...state.entities)
    },
    // update election
    electionUpdate: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
      state.entities = state.entities.map((entity) => {
        if (entity.uuid === action.payload.election.uuid) {
          return action.payload.election
        }
        return entity
      })
    },
    //change status
    electionStatusUpdate: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
      state.entities = state.entities.map((entity) => {
        if (entity.uuid === action.payload.election.uuid) {
          return action.payload.election
        }
        return entity
      })
    },
    // delete election
    electionDelete: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
      state.entities = state.entities.filter((el) => el.uuid !== action.payload.uuid)
    },
  },
})
