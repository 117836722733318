import axios from 'axios'
import {ElectionModel} from '../models/ElectionModel'

const API_URL = process.env.REACT_APP_API_URL || 'https://api.assempece.org.br/api/v1'

export const LIST_ELECTIONS_URL = `${API_URL}/elections`
export const ELECTION_URL = `${API_URL}/election`

export function listElections(queryParams: any) {
  return axios.post(LIST_ELECTIONS_URL, queryParams)
}

export function getElection(uuid: string) {
  return axios.get(`${ELECTION_URL}/${uuid}`)
}

export function electionStart() {
  return axios.get(`${ELECTION_URL}/find?status=Iniciada`)
}

export function createElection(election: ElectionModel) {
  return axios.post(ELECTION_URL, election)
}

export function updateElection(election: ElectionModel) {
  return axios.put(`${ELECTION_URL}/${election.uuid}`, election)
}

export function deleteElection(uuid: string) {
  return axios.delete(`${ELECTION_URL}/${uuid}`)
}

export function changeStatus(topic: ElectionModel) {
  return axios.put(`${ELECTION_URL}/changeStatus/${topic.uuid}`, topic)
}

export function printPDF(uuid: string) {
  return axios.get(`${ELECTION_URL}/printPDF/${uuid}`)
}
