import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useDispatch } from 'react-redux'
import { SessionModel } from './models/SessionModel'
import * as actions from './redux/sessionsActions'
import * as server from './redux/sessionsCrud'

export interface SessionStatusProps {
  uuid: string
  show: boolean
  item: SessionModel | undefined
  handleClose: () => void
}

export function SessionStatusDialog(props: SessionStatusProps) {
  const dispatch: any = useDispatch()
  const [flag, setFlag] = useState(true)

  const checkStarted = async () => {
    const obj = { page: 1, name: '', status: 'Iniciada' }
    await server.listSessions(obj).then((response) => {
      if (response.data.data.length > 0) {
        setFlag(false)
      }
    })
  }

  useEffect(() => {
    if (props.item?.status !== 'Iniciada') {
      checkStarted()
    }
  }, [props])

  const updateStatusSession = async () => {
    let copy = Object.assign({}, props.item)
    switch (props.item?.status) {
      case 'Ativa':
        copy.status = 'Iniciada'
        break

      case 'Iniciada':
        copy.status = 'Encerrada'
        break

      case 'Encerrada':
        copy.status = 'Ativa'
        break

      default:
        break
    }
    setFlag(true)
    dispatch(actions.updateSession(copy))
    props.handleClose()
  }

  const textByStatus = (status: string) => {
    switch (status) {
      case 'Ativa':
        if (flag) {
          return 'Certeza que quer iniciar a sessão?'
        }
        return 'Já existe uma sessão ativa!'

      case 'Iniciada':
        return 'Certeza que quer encerrar a sessão?'

      case 'Encerrada':
        return 'Sessão encerrada, certeza que deseja reativar a sessão?'

      default:
        break
    }
  }

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>Status da Sessão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{textByStatus(props.item?.status!)}</p>
        </Modal.Body>

        <Modal.Footer>
          <button type='button' className='btn btn-light' onClick={() => {
            props.handleClose()
          }}>
            Fechar
          </button>
          {
            flag || props.item?.status !== 'Ativa' ? (
              <button type='button' className='btn btn-primary' onClick={updateStatusSession}>
                Confirmar
              </button>
            ) : null
          }
        </Modal.Footer>
      </Modal>
    </>
  )
}
