/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { ElectionCard } from '../modules/elections'

const ElectionPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-12'>
      <div className='col-xxl-12'>
        <ElectionCard />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const ElectionWrapper: FC = () => {
  return (
    <>
      <ElectionPage />
    </>
  )
}

export { ElectionWrapper }

