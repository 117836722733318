import {ElectionModel} from '../models/ElectionModel'
import * as requestFromServer from './electionsCrud'
import {callTypes, electionsSlice} from './electionsSlice'

const {actions} = electionsSlice

export const fetchElections = (queryParams: any) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.list}))
  return await requestFromServer
    .listElections(queryParams)
    .then((response) => {
      const entities = response.data.data
      const totalCount = response.data.meta.total
      const lastPage = response.data.meta.last_page
      const firstPage = response.data.meta.first_page
      const curPage = response.data.meta.current_page
      dispatch(actions.electionsFetched({entities, totalCount, lastPage, firstPage, curPage}))
    })
    .catch((error: any) => {
      error.clientMessage = "Can't find elections"
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.list,
        })
      )
    })
}

export const electionStart = () => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return await requestFromServer
    .electionStart()
    .then((response) => {
      const entityStart = response.data
      dispatch(actions.electionStart({entityStart}))
    })
    .catch((error: any) => {
      error.clientMessage = "Can't find elections"
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      )
    })
}

export const fetchElection = (id: string | undefined) => async (dispatch: any) => {
  if (id !== undefined) {
    if (!id) {
      return dispatch(electionsSlice.actions.electionFetched({electionForEdit: undefined}))
    }

    dispatch(electionsSlice.actions.startCall({callType: callTypes.action}))
    return await requestFromServer
      .getElection(id)
      .then((response) => {
        const obj = response.data
        dispatch(actions.electionFetched({electionForEdit: obj}))
      })
      .catch((error) => {
        error.clientMessage = "Can't find election"
        dispatch(
          electionsSlice.actions.catchError({
            error,
            callType: callTypes.action,
          })
        )
      })
  }
}

export const createElection = (electionForCreation: ElectionModel) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .createElection(electionForCreation)
    .then((response) => {
      const election = response.data
      dispatch(actions.electionCreate({election}))
    })
    .catch((error) => {
      error.clientMessage = "Can't create comment"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}

export const updateElection = (electionToUpdate: ElectionModel) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .updateElection(electionToUpdate)
    .then((response) => {
      const election = response.data
      dispatch(actions.electionUpdate({election}))
    })
    .catch((error) => {
      error.clientMessage = "Can't update election"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}

export const changeStatus = (changedElection: ElectionModel) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return await requestFromServer
    .changeStatus(changedElection)
    .then((response) => {
      const election = response.data
      dispatch(actions.electionStatusUpdate({election}))
      return election
    })
    .catch((error) => {
      error.clientMessage = "Can't change status election"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}

export const deleteElection = (uuid: string) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .deleteElection(uuid)
    .then((response) => {
      dispatch(actions.electionDelete({uuid}))
    })
    .catch((error) => {
      error.clientMessage = "Can't delete election"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}
/*


export const deleteComments = ids => dispatch => {
  dispatch(commentsSlice.actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteComments(ids)
    .then(() => {
      dispatch(commentsSlice.actions.commentsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete comments";
      dispatch(
        commentsSlice.actions.catchError({ error, callType: callTypes.action })
      );
    });
}; */
