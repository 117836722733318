import {SessionModel} from '../models/SessionModel'
import * as requestFromServer from './sessionsCrud'
import {callTypes, sessionsSlice} from './sessionsSlice'

const {actions} = sessionsSlice

export const setActualSession = (obj: SessionModel | undefined) => async (dispatch: any) => {
  dispatch(actions.actualSession({obj}))
}

export const fetchSessions = (queryParams: any) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.list}))
  return await requestFromServer
    .listSessions(queryParams)
    .then((response) => {
      const entities = response.data.data
      const totalCount = response.data.meta.total
      const lastPage = response.data.meta.last_page
      const firstPage = response.data.meta.first_page
      const curPage = response.data.meta.current_page
      dispatch(actions.sessionsFetched({entities, totalCount, lastPage, firstPage, curPage}))
    })
    .catch((error: any) => {
      error.clientMessage = "Can't find sessions"
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.list,
        })
      )
    })
}

export const fetchSession = (id: string | undefined) => async (dispatch: any) => {
  if (id !== undefined) {
    if (!id) {
      return dispatch(sessionsSlice.actions.sessionFetched({sessionForEdit: undefined}))
    }

    dispatch(sessionsSlice.actions.startCall({callType: callTypes.action}))
    return await requestFromServer
      .getSession(id)
      .then((response) => {
        const obj = response.data
        dispatch(actions.sessionFetched({sessionForEdit: obj}))
      })
      .catch((error) => {
        error.clientMessage = "Can't find session"
        dispatch(
          sessionsSlice.actions.catchError({
            error,
            callType: callTypes.action,
          })
        )
      })
  }
}

export const createSession = (sessionForCreation: SessionModel) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .createSession(sessionForCreation)
    .then((response) => {
      const session = response.data
      dispatch(actions.sessionCreate({session}))
    })
    .catch((error) => {
      error.clientMessage = "Can't create comment"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}

export const updateSession = (session: SessionModel) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .updateSession(session)
    .then(() => {
      dispatch(actions.sessionUpdate({session}))
    })
    .catch((error) => {
      error.clientMessage = "Can't update session"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}

export const deleteSession = (uuid: string) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .deleteSession(uuid)
    .then((response) => {
      dispatch(actions.sessionDelete({uuid}))
    })
    .catch((error) => {
      error.clientMessage = "Can't delete session"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}
/*


export const deleteComments = ids => dispatch => {
  dispatch(commentsSlice.actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteComments(ids)
    .then(() => {
      dispatch(commentsSlice.actions.commentsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete comments";
      dispatch(
        commentsSlice.actions.catchError({ error, callType: callTypes.action })
      );
    });
}; */
