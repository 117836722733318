import { useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ElectionEditForm } from './ElectionEditForm'
import { ElectionModel } from './models/ElectionModel'
import * as actions from './redux/electionsActions'

export interface ElectionEditProps {
  topic: ElectionModel | undefined
  show: boolean
  handleClose: () => void
}

const initialValues = {
  uuid: undefined,
  name: '',
  number_order: undefined,
  status: '',
  is_multiple: false,
  standard_options: false,
  secret_vote: '',
}

export function ElectionEditDialog(props: ElectionEditProps) {
  const dispatch: any = useDispatch()
  const { electionForEdit } = useSelector(
    (state: any) => ({
      electionForEdit: state.election.electionForEdit,
    }),
    shallowEqual
  )

  useEffect(() => {
    dispatch(actions.fetchElection(props.topic?.uuid!))
  }, [props.topic, dispatch])

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>Eleição</Modal.Title>
        </Modal.Header>
        <ElectionEditForm obj={props.topic ? electionForEdit : initialValues} handleClose={props.handleClose} />
      </Modal>
    </>
  )
}
