import {useEffect} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as actions from './redux/usersActions'
import {UserEditForm} from './UserEditForm'

export interface UserEditProps {
  uuid?: string
  show: boolean
  handleClose: () => void
}

const initialValues = {
  uuid: undefined,
  simple_name: '',
  complete_name: '',
  email: '',
  cpf: '',
  profile: '',
  password: '',
}

export function UserEditDialog(props: UserEditProps) {
  const dispatch: any = useDispatch()
  const {userForEdit} = useSelector(
    (state: any) => ({
      userForEdit: state.user.userForEdit,
    }),
    shallowEqual
  )

  useEffect(() => {
    dispatch(actions.fetchUser(props.uuid))
  }, [props.uuid, dispatch])

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>Usuário</Modal.Title>
        </Modal.Header>
        <UserEditForm obj={userForEdit || initialValues} handleClose={props.handleClose} />
      </Modal>
    </>
  )
}
