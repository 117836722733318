import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useDispatch } from 'react-redux'
import { TimeToVote } from '../../utils/TimeToVote'
import { afterCloseStatus, afterOpenStatus, afterStartStatus, initialStatus, TopicStatus } from '../../utils/TopicStatus'
import { TopicModel } from './models/TopicModel'
import * as actions from './redux/topicsActions'

export interface TopicEditProps {
  obj: TopicModel | undefined
  existOpenOrStart: TopicModel | undefined
  show: boolean
  handleClose: () => void
}

export function TopicEditStatusDialog(props: TopicEditProps) {
  const [error, setError] = useState(false)
  const [status, setStatus] = useState<any[]>([])
  const [selectedStatus, setSelectedStatus] = useState('')
  const [time, setTime] = useState('')
  const dispatch: any = useDispatch()

  const getStatusEnum = async () => {
    switch (props.obj?.status) {
      case TopicStatus.ACTIVE.value:
        if (props.existOpenOrStart) {
          setStatus(initialStatus.filter(s => s !== TopicStatus.OPENED))
        } else {
          setStatus(initialStatus)
        }
        break

      case TopicStatus.OPENED.value:
        if (props.obj.options.length >= 2) {
          setStatus(afterOpenStatus)
        } else {
          setStatus(afterOpenStatus.filter(s => s !== TopicStatus.STARTED))
        }
        break

      case TopicStatus.STARTED.value:
        setStatus(afterStartStatus)
        break

      default:
        setStatus(afterCloseStatus)
        break

    }
  }

  const updateTopic = () => {
    let obj = { status: selectedStatus, duration: Number(time), uuid: props.obj?.uuid }
    dispatch(actions.changeStatus(obj)).then((response: any) => {
      if (response) {
        setSelectedStatus('')
        setError(false)
        props.handleClose()
      } else {
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 2000);
      }
    })
  }

  useEffect(() => {
    getStatusEnum()
  }, [props])

  return (
    <>
      <Modal show={props.show} onHide={() => {
        setSelectedStatus('')
        setTime('')
        props.handleClose()
      }}>
        <Modal.Header>
          <Modal.Title>Gerenciar Pauta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-10' style={{ textAlign: 'left' }}>
            {error ? (
              <div className='mb-10' style={{ textAlign: 'center', borderRadius: 10 }}>
                <label className='form-label' style={{ color: 'red' }}>Já existe uma pauta iniciada ou em discussão, aguarde o encerramento!</label>
              </div>
            ) : null}
            <>
              {
                props.obj?.options.length < 2 ? (
                  <>
                    <label className='form-label' style={{ color: 'red' }}>Não é possível iniciar a votação da pauta com menos de 2 opções de voto.</label>
                  </>
                ) : null}
              <label className='form-label'>Selecione uma ação:</label>
              <select
                value={selectedStatus}
                onChange={(e) => {
                  console.log(e.target.value)
                  setSelectedStatus(e.target.value)
                }}
                className='form-select form-select-solid'
                aria-label='Status'
                placeholder='Selecione um status'
              >
                <option>Selecione...</option>
                {status?.map((s) => {
                  return <option value={s.value}>{s.text}</option>
                })}
              </select>
            </>
          </div>
          {
            selectedStatus === TopicStatus.STARTED.value ? (
              <>
                <div className='mb-10' style={{ textAlign: 'left' }}>
                  <label className='form-label'>Selecione uma tempo para votação:</label>
                  <select
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    className='form-select form-select-solid'
                    aria-label='Status'
                    placeholder='Selecione um status'
                  >
                    <option>Selecione...</option>
                    {Object.values(TimeToVote).map((t) => {
                      return <option value={t.value}>{t.text}</option>
                    })}
                  </select>
                </div>
              </>
            ) : null
          }
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-light' onClick={() => {
            setSelectedStatus('')
            setTime('')
            props.handleClose()
          }}>
            Fechar
          </button>
          {props.obj?.options.length >= 2 || props.obj?.status === 'Ativa' ?
            (
              <button type='button' className='btn btn-primary' onClick={updateTopic}>
                Salvar
              </button>
            ) : null}
        </Modal.Footer>
      </Modal>
    </>
  )
}
