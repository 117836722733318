import {createSlice} from '@reduxjs/toolkit'
import {UserReduxModel} from '../models/UserReduxModel'

const initialUserState: UserReduxModel = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  curPage: 1,
  firstPage: 1,
  lastPage: 1,
  entities: [],
  userForEdit: undefined,
  actualUser: undefined,
  lastError: null,
  type: 'list',
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const usersSlice = createSlice({
  name: 'users',
  initialState: initialUserState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.lastError = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    actualUser: (state, action) => {
      const {uuid} = action.payload
      state.listLoading = false
      state.lastError = null
      state.actualUser = uuid
    },
    // type view
    usersType: (state, action) => {
      const {type} = action.payload
      state.listLoading = false
      state.lastError = null
      state.userForEdit = undefined
      state.type = type
    },
    // getuserById
    userFetched: (state, action) => {
      state.actionsLoading = false
      state.userForEdit = action.payload.userForEdit
      state.lastError = null
    },
    // findusers
    usersFetched: (state, action) => {
      const {totalCount, entities, lastPage, firstPage, curPage} = action.payload
      state.listLoading = false
      state.actionsLoading = false
      state.firstPage = firstPage
      state.lastPage = lastPage
      state.curPage = curPage
      state.lastError = null
      state.entities = entities
      state.totalCount = totalCount
    },
    // create user
    userCreate: (state, action) => {
      state.actionsLoading = false
      state.lastError = null
      state.entities = [action.payload.user].concat(...state.entities)
    },
    // update user
    userUpdate: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
      state.entities = state.entities.map((entity) => {
        if (entity.uuid === action.payload.user.uuid) {
          return action.payload.user
        }
        return entity
      })
    },
    // delete user
    userDelete: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
      state.entities = state.entities.filter((el) => el.uuid !== action.payload.uuid)
    },
    // change password
    changePassword: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
    },
  },
})
