/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import 'moment/locale/pt-br'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Pagination from '../components/Pagination'
import { formatterCPF } from '../../../util/formatter'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { UserProfile } from '../../utils/UserProfile'
import { UserModel } from './models/UserModel'
import * as actions from './redux/usersActions'
import { UserChangePasswordDialog } from './UserChangePasswordDialog'
import { UserDeleteDialog } from './UserDeleteDialog'
import { UserEditDialog } from './UserEditDialog'

const UserCard = () => {
  moment.locale('pt-br')
  const [userUuid, setUserUuId] = useState('')
  const [page, setPage] = useState(1)
  const [name, setName] = useState('')
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const { currentState } = useSelector(
    (state: any) => ({
      currentState: state.user,
    }),
    shallowEqual
  )
  const { entities, firstPage, lastPage, curPage } = currentState

  const createPagination = (firstPage: number, lastPage: number) => {
    let array: number[] = []
    for (let i = firstPage; i <= lastPage; i++) {
      array.push(i)
    }
    return array
  }

  const onPaginationClick = (event: any) => {
    setPage(event.target.outerText)
  }

  const applyGeneralFilter = (page: number, name: string) => {
    const obj = { page: page, name: name }
    dispatch(actions.fetchUsers(obj))
  }

  const applyFilter = () => {
    applyGeneralFilter(1, name)
  }

  const removeFilter = () => {
    setName('')
    applyGeneralFilter(1, '')
  }

  let array = createPagination(firstPage, lastPage)

  const dispatch = useDispatch()
  useEffect(() => {
    applyGeneralFilter(page, name)
  }, [dispatch, page])

  const changeUserStatus = (user: UserModel) => {
    let copy = Object.assign({}, user)
    switch (user.status) {
      case 'Ativo':
        copy.status = 'Desativado'
        break

      case 'Desativado':
        copy.status = 'Ativo'
        break

      default:
        break
    }
    dispatch(actions.updateUser(copy))
  }

  const iconByStatus = (status: string) => {
    switch (status) {
      case 'Ativo':
        return 'fa fa-lock'

      case 'Desativado':
        return 'fa fa-unlock'

      default:
        break
    }
  }

  const profileByUser = (profile: string) => {
    let badgeColor = 'warning'
    let text = UserProfile.USER.text
    switch (profile) {
      case UserProfile.ADMIN.value:
        badgeColor = 'primary'
        text = UserProfile.ADMIN.text
        break

      case UserProfile.MEMBER.value:
        badgeColor = 'info'
        text = UserProfile.MEMBER.text
        break
    }
    return (
      <span className={`badge badge-light-${badgeColor}`}>{text}</span>
    )
  }

  return (
    <>
      <UserDeleteDialog
        uuid={userUuid}
        show={showDelete}
        handleClose={() => setShowDelete(false)}
      />
      <UserEditDialog uuid={userUuid} show={showEdit} handleClose={() => setShowEdit(false)} />
      <UserChangePasswordDialog
        uuid={userUuid}
        show={showChangePassword}
        handleClose={() => setShowChangePassword(false)}
      />
      <div className='card card-xl-stretch mb-xl-12'>
        <div className='me-5 me-md-10 pt-5 flex-grow-1 flex-lg-grow-0 text-center'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logo-assempece.png')}
            className='logo-default h-150px'
            style={{ maxWidth: document.body.clientWidth - 40 }}
          />
        </div>
        <div className="separator my-5"></div>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-1 mt-1'>
              <KTSVG
                path='/media/icons/duotune/general/gen049.svg'
                className='svg-icon svg-icon-4x svg-icon-primary'
              />
            </div>
            <div className='flex-grow-1'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3'>Usuários</span>
                <span className='text-muted fw-bold fs-7'>Gerenciamento de todos usuários</span>
              </h3>
            </div>
          </div>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-light-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
              onClick={() => {
                setUserUuId('')
                setShowEdit(true)
              }}
            >
              Incluir
              <i className='fas fa-user ms-3 fs-2'></i>
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Filter */}
          <div className='d-flex flex-wrap my-10'>
            <div className='flex-grow-1 me-2'>
              <input
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
                className='form-control form-control-solid'
                placeholder='Filtre usuários aqui'
              />
            </div>
            <div className='mt-5 mt-md-0'>
              <button
                type='button'
                onClick={applyFilter}
                className='btn btn-light-success float-right me-2'
              >
                <i className='fas fa-filter fs-4'></i>
                Filtrar
              </button>
              <button
                type='button'
                onClick={removeFilter}
                className='btn btn-light-danger float-right'
              >
                <i className='fas fa-times fs-4'></i>
                Cancelar
              </button>
            </div>
          </div>
          {/* end::Filter */}
          <div className='separator separator-dashed border-gray-300 border-1 mt-3'></div>
          {/* begin::DataTable */}
          <div className=''>
            <div className='table-responsive'>
              {/* begin::Table container */}
              {/* begin::Table */}
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-2 gy-3 table-striped'>
                {/* begin::Table head */}
                <thead className='border-gray-300 px-3'>
                  <tr className='fw-bolder text-muted border-gray-400 '>
                    <th className='col-1 min-w-150px'>CPF</th>
                    <th className='col-1'>Nome</th>
                    <th className='col-1'>Último acesso</th>
                    <th className='col-1'>Perfil</th>
                    <th className='col-1'>Status</th>
                    <th className='col-1 text-center'>Ações</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody className='border-gray-300'>
                  {entities
                    ? entities.map((item: any) => (
                      <tr key={item.uuid}>
                        <td className='text-dark fw-bolder text-hover-primary fs-6'>
                          {formatterCPF(item?.cpf)}
                        </td>
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                            {item?.simple_name}
                          </span>
                          <span className='text-muted fw-bold text-muted d-block fs-9'>
                            {item?.email}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                            {item?.last_access
                              ? moment(item?.last_access).fromNow()
                              : 'Nunca acessou'}
                          </span>
                          <span className='text-muted fw-bold text-muted d-block fs-9'>
                            {item?.last_ip}
                          </span>
                        </td>
                        <td>
                          {profileByUser(item?.profile)}
                        </td>
                        <td>
                          {item?.status === 'Ativo' ? (
                            <span className='badge badge-light-success'>Ativo</span>
                          ) : (
                            <span className='badge badge-light-danger'>Inativo</span>
                          )}
                        </td>
                        <td className='text-end'>
                          <button
                            type='button'
                            className='btn btn-icon btn-light-success me-1'
                            onClick={() => changeUserStatus(item)}
                            style={{ width: 30, height: 30 }}
                            data-toggle="tooltip" title="Ativar/Desativar"
                          >
                            <i className={`${iconByStatus(item.status)} fs-4`}></i>
                          </button>
                          <button
                            type='button'
                            className='btn btn-icon btn-light-primary me-1'
                            style={{ width: 30, height: 30 }}
                            onClick={() => {
                              setUserUuId(item.uuid)
                              setShowChangePassword(true)
                            }}
                            data-toggle="tooltip" title="Alterar Senha"
                          >
                            <i className='fas fa-key fs-4'></i>
                          </button>
                          <button
                            type='button'
                            className='btn btn-icon btn-light-primary me-1 mt-1 mt-md-0'
                            style={{ width: 30, height: 30 }}
                            onClick={() => {
                              setUserUuId(item.uuid)
                              setShowEdit(true)
                            }}
                            data-toggle="tooltip" title="Editar"
                          >
                            <i className='fas fa-pen fs-4'></i>
                          </button>
                          <button
                            type='button'
                            className='btn btn-icon btn-light-danger me-1 mt-1 mt-md-0'
                            style={{ width: 30, height: 30 }}
                            onClick={() => {
                              setUserUuId(item.uuid)
                              setShowDelete(true)
                            }}
                            data-toggle="tooltip" title="Excluir"
                          >
                            <i className='fas fa-trash fs-4'></i>
                          </button>
                        </td>
                      </tr>
                    ))
                    : null}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
              {/* end::Table container */}
              {/* begin::Pagination */}
              <Pagination curPage={curPage} firstPage={firstPage} lastPage={lastPage} page={page} setPage={setPage} />
              {/* end::Pagination */}
            </div>
          </div>
        </div>
      </div>
      {/* end::DataTable */}
    </>
  )
}

export { UserCard }

