import {Modal} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import * as actions from './redux/usersActions'

export interface UserEditProps {
  uuid: string
  show: boolean
  handleClose: () => void
}

export function UserChangePasswordDialog(props: UserEditProps) {
  const dispatch: any = useDispatch()
  const passwordNew = Math.random().toString(36).slice(7).toUpperCase()
  const changePasswordUser = () => {
    dispatch(actions.changePasswordUser(props.uuid, passwordNew)).then(() => {
      props.handleClose()
    })
  }

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>Tem certeza que deseja alterar senha?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className='fw-bold text-muted text-center d-block fs-3'>Nova senha será</span>

          <div className='d-flex flex-wrap justify-content-center'>
            <div className='border-2 border-gray-300 border-dashed rounded py-3 px-4 mb-3 bg-light w-20 text-center'>
              <div className='fs-2 fw-bolder text-center'>{passwordNew}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-light' onClick={props.handleClose}>
            Fechar
          </button>
          <button type='button' className='btn btn-primary' onClick={changePasswordUser}>
            Confirmar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
