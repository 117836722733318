import {TopicModel} from '../models/TopicModel'
import * as requestFromServer from './topicsCrud'
import {callTypes, topicsSlice} from './topicsSlice'

const {actions} = topicsSlice

export const fetchTopics = (queryParams: any) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.list}))
  return await requestFromServer
    .listTopics(queryParams)
    .then((response) => {
      const entities = response.data.data
      const totalCount = response.data.meta.total
      const lastPage = response.data.meta.last_page
      const firstPage = response.data.meta.first_page
      const curPage = response.data.meta.current_page
      dispatch(actions.topicsFetched({entities, totalCount, lastPage, firstPage, curPage}))
    })
    .catch((error: any) => {
      error.clientMessage = "Can't find topics"
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.list,
        })
      )
    })
}

export const fetchOpenOrStartTopics = (queryParams: any) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.list}))
  return await requestFromServer
    .find()
    .then((response) => {
      if (response.data) {
        const entity = response.data
        dispatch(actions.topicsOpenOrStartFetch({entity}))
      } else {
        const error = {clientMessage: "Can't find topics"}
        dispatch(
          actions.catchError({
            error,
            callType: callTypes.list,
          })
        )
      }
    })
    .catch((error: any) => {
      error.clientMessage = "Can't find topics"
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.list,
        })
      )
    })
}

export const fetchTopic = (id: string | undefined) => async (dispatch: any) => {
  if (id !== undefined) {
    if (!id) {
      return dispatch(topicsSlice.actions.topicFetched({topicForEdit: undefined}))
    }

    dispatch(topicsSlice.actions.startCall({callType: callTypes.action}))
    return await requestFromServer
      .getTopic(id)
      .then((response) => {
        const obj = response.data
        dispatch(actions.topicFetched({topicForEdit: obj}))
      })
      .catch((error) => {
        error.clientMessage = "Can't find topic"
        dispatch(
          topicsSlice.actions.catchError({
            error,
            callType: callTypes.action,
          })
        )
      })
  }
}

export const createTopic = (topicForCreation: TopicModel) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .createTopic(topicForCreation)
    .then((response) => {
      const topic = response.data
      dispatch(actions.topicCreate({topic}))
    })
    .catch((error) => {
      error.clientMessage = "Can't create comment"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}

export const updateTopic = (topicToUpdate: TopicModel) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .updateTopic(topicToUpdate)
    .then((response) => {
      const topic = response.data
      dispatch(actions.topicUpdate({topic}))
    })
    .catch((error) => {
      error.clientMessage = "Can't update topic"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}

export const changeStatus = (changedTopic: any) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return await requestFromServer
    .changeStatus(changedTopic)
    .then((response) => {
      const topic = response.data
      dispatch(actions.topicStatusUpdate({topic}))
      return topic
    })
    .catch((error) => {
      error.clientMessage = "Can't change status election"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}

export const deleteTopic = (uuid: string) => async (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .deleteTopic(uuid)
    .then((response) => {
      dispatch(actions.topicDelete({uuid}))
    })
    .catch((error) => {
      error.clientMessage = "Can't delete topic"
      dispatch(actions.catchError({error, callType: callTypes.action}))
    })
}
/*


export const deleteComments = ids => dispatch => {
  dispatch(commentsSlice.actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteComments(ids)
    .then(() => {
      dispatch(commentsSlice.actions.commentsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete comments";
      dispatch(
        commentsSlice.actions.catchError({ error, callType: callTypes.action })
      );
    });
}; */
