import {createSlice} from '@reduxjs/toolkit'
import {SessionReduxModel} from '../models/SessionReduxModel'

const initialSessionState: SessionReduxModel = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  curPage: 1,
  firstPage: 1,
  lastPage: 1,
  entities: [],
  sessionForEdit: undefined,
  actualSession: undefined,
  lastError: null,
  type: 'list',
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const sessionsSlice = createSlice({
  name: 'sessions',
  initialState: initialSessionState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.lastError = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    actualSession: (state, action) => {
      const {obj} = action.payload
      state.listLoading = false
      state.lastError = null
      state.actualSession = obj
    },
    // type view
    sessionsType: (state, action) => {
      const {type} = action.payload
      state.listLoading = false
      state.lastError = null
      state.sessionForEdit = undefined
      state.type = type
    },
    // getsessionById
    sessionFetched: (state, action) => {
      state.actionsLoading = false
      state.sessionForEdit = action.payload.sessionForEdit
      state.lastError = null
    },
    // findsessions
    sessionsFetched: (state, action) => {
      const {totalCount, entities, lastPage, firstPage, curPage} = action.payload
      state.listLoading = false
      state.actionsLoading = false
      state.firstPage = firstPage
      state.lastPage = lastPage
      state.curPage = curPage
      state.lastError = null
      state.entities = entities
      state.totalCount = totalCount
    },
    // create session
    sessionCreate: (state, action) => {
      state.actionsLoading = false
      state.lastError = null
      state.entities = [action.payload.session].concat(...state.entities)
    },
    // update session
    sessionUpdate: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
      state.entities = state.entities.map((entity) => {
        if (entity.uuid === action.payload.session.uuid) {
          return action.payload.session
        }
        return entity
      })
    },
    // delete session
    sessionDelete: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
      state.entities = state.entities.filter((el) => el.uuid !== action.payload.uuid)
    },
  },
})
