import { Modal } from 'react-bootstrap-v5'
import { useDispatch } from 'react-redux'
import { KTSVG } from '../../../_metronic/helpers'
import * as actions from './redux/sessionsActions'

export interface SessionEditProps {
  uuid: string
  show: boolean
  handleClose: () => void
}

export function SessionDeleteDialog(props: SessionEditProps) {
  const dispatch: any = useDispatch()

  const deleteSession = () => {
    dispatch(actions.deleteSession(props.uuid)).then(() => {
      props.handleClose()
    })
  }

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>Exclusão de Sessão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza que deseja excluir essa sessão?</p>
        </Modal.Body>

        <Modal.Footer>
          <button type='button' className='btn btn-light' onClick={props.handleClose}>
            Fechar
          </button>
          <button type='button' className='btn btn-primary' onClick={deleteSession}>
            Excluir
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
