import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as user from '../../app/modules/users'
import * as session from '../../app/modules/sessions'
import * as topic from '../../app/modules/topics'
import * as election from '../../app/modules/elections'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  user: user.usersSlice.reducer,
  session: session.sessionsSlice.reducer,
  topic: topic.topicsSlice.reducer,
  election: election.electionsSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
