import axios from 'axios'
import {SessionModel} from '../models/SessionModel'

const API_URL = process.env.REACT_APP_API_URL || 'https://api.assempece.org.br/api/v1'

export const LIST_SESSIONS_URL = `${API_URL}/sessions`
export const SESSION_URL = `${API_URL}/session`

export function listSessions(queryParams: any) {
  return axios.post(LIST_SESSIONS_URL, queryParams)
}

export function getSession(uuid: string) {
  return axios.get(`${SESSION_URL}/${uuid}`)
}

export function getNextSessions() {
  return axios.get(`${SESSION_URL}/findNextSession`)
}

export function createSession(session: SessionModel) {
  return axios.post(SESSION_URL, session)
}

export function updateSession(session: SessionModel) {
  return axios.put(`${SESSION_URL}/${session.uuid}`, session)
}

export function deleteSession(uuid: string) {
  return axios.delete(`${SESSION_URL}/${uuid}`)
}

export function printSimplePDF(uuid: string) {
  return axios.get(`${SESSION_URL}/printSimplePDF/${uuid}`)
}

export function printCompletePDF(uuid: string) {
  return axios.get(`${SESSION_URL}/printCompletePDF/${uuid}`)
}
