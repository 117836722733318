/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { ElectionModel } from "../elections/models/ElectionModel";
import { SessionModel } from "../sessions/models/SessionModel";
import * as serverSessionCRUD from "../sessions/redux/sessionsCrud";
import * as serverElectionCRUD from "../elections/redux/electionsCrud";
import { DashboardAdmin } from "./DashboardAdmin";
import { DashboardUserVote } from "./DashboardUserVote";
const ENDPOINT = process.env.SERVER_API_URL || "https://api.assempece.org.br";

const DashboardCard = () => {
  const [nextSessions, setNextSessions] = useState<SessionModel[]>([]);
  const [startedElection, setStartedElection] = useState<ElectionModel>();
  const [startedSession, setStartedSession] = useState<SessionModel>();

  const { currentAuthState } = useSelector(
    (state: any) => ({
      currentAuthState: state.auth,
    }),
    shallowEqual
  );

  const applyGeneralFilter = async () => {
    await serverSessionCRUD.getNextSessions().then((response) => {
      setNextSessions(response.data);
    });
  };

  const getStartedElection = async () => {
    await serverElectionCRUD.electionStart().then((response) => {
      setStartedElection(response.data);
    });
  };

  useEffect(() => {
    if (currentAuthState.user.profile === "Administrator") {
      applyGeneralFilter();
    }
    const socket = socketIOClient(ENDPOINT);
    socket.on("index:started", (data) => {
      setStartedSession(data);
    });
    socket.on("index:electionStarted", (data) => {
      setStartedElection(data);
    });
  }, []);

  return (
    <>
      <div className="card card-xl-stretch mb-xl-12">
        <div className='me-5 me-md-10 pt-5 flex-grow-1 flex-lg-grow-0 text-center'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logo-assempece.png')}
            className='logo-default h-150px'
            style={{ maxWidth: document.body.clientWidth - 40 }}
          />
        </div>
        <div className="separator my-5"></div>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-1 mt-1">
              <KTSVG
                path="/media/icons/duotune/general/gen025.svg"
                className="svg-icon svg-icon-4x svg-icon-primary"
              />
            </div>
            <div className="flex-grow-1">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3">
                  Sistema de Votação Online
                </span>
                <span className="text-muted fw-bold fs-7">
                  Gerencie a votação de pautas e eleições
                </span>
              </h3>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          <div className="align-self-center">
            {currentAuthState.user.profile === "Administrator" ? (
              <DashboardAdmin nextSessions={nextSessions} />
            ) : (
              <DashboardUserVote
                nextSessions={nextSessions}
                startedElection={startedElection}
                startedSession={startedSession}
              />
            )}
          </div>
        </div>
        {/* end::Body */}
      </div>
    </>
  );
};

export { DashboardCard };
