/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { DashboardCard } from '../../modules/dashboard'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-12'>
      <div className='col-xxl-12'>
        <DashboardCard />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  return (
    <>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }

