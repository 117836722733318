import { useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import * as actions from './redux/sessionsActions'
import { SessionEditForm } from './SessionEditForm'

export interface SessionEditProps {
  uuid?: string
  show: boolean
  handleClose: () => void
}

const initialValues = {
  uuid: undefined,
  name: '',
  number_order: undefined,
  date: undefined,
  type: '',
}

export function SessionEditDialog(props: SessionEditProps) {
  // Ideas Redux state
  const dispatch: any = useDispatch()
  const { sessionForEdit } = useSelector(
    (state: any) => ({
      sessionForEdit: state.session.sessionForEdit,
    }),
    shallowEqual
  )

  useEffect(() => {
    // server call for getting Idea by id
    dispatch(actions.fetchSession(props.uuid))
  }, [props.uuid, dispatch])

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>Sessão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SessionEditForm obj={sessionForEdit || initialValues} handleClose={props.handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
