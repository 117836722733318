/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { SessionStatus } from '../../utils/SessionStatus';
import { TopicStatus } from '../../utils/TopicStatus';
import Pagination from '../components/Pagination';
import { TopicModel } from '../topics/models/TopicModel';
import { SessionModel } from './models/SessionModel';
import * as actions from './redux/sessionsActions';
import * as server from './redux/sessionsCrud';
import { SessionDeleteDialog } from './SessionDeleteDialog';
import { SessionEditDialog } from './SessionEditDialog';
import { SessionStatusDialog } from './SessionStatusDialog';


const SessionCard = () => {
  const [showStatus, setShowStatus] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [session, setSession] = useState<SessionModel>()
  const [sessionUuid, setSessionUuid] = useState('')
  const [page, setPage] = useState(1)
  const [name, setName] = useState('')

  const { currentState } = useSelector(
    (state: any) => ({
      currentState: state.session,
    }),
    shallowEqual
  )
  const { entities, firstPage, lastPage, curPage } = currentState

  const buttonByStatus = (status: string) => {
    switch (status) {
      case 'Ativa':
        return 'success'

      case 'Iniciada':
        return 'primary'

      case 'Encerrada':
        return 'warning'

      default:
        break
    }
  }

  const applyGeneralFilter = (page: number, name: string) => {
    const obj = { page: page, name: name }
    dispatch(actions.fetchSessions(obj))
  }

  const applyFilter = () => {
    applyGeneralFilter(1, name)
  }

  const removeFilter = () => {
    setName('')
    applyGeneralFilter(1, '')
  }

  const changeStatusPermission = (item: SessionModel) => {
    let bool = item.topics.filter((item: TopicModel) => item.status === TopicStatus.OPENED.value || item.status === TopicStatus.STARTED.value).length > 0
    return !bool
  }

  const dispatch = useDispatch()
  useEffect(() => {
    applyGeneralFilter(page, name)
  }, [dispatch, page])

  const goToTopics = (obj: SessionModel) => {
    dispatch(actions.setActualSession(obj))
  }

  const printSimplePDF = (uuid: string) => {
    server.printSimplePDF(uuid).then((response) => {
      const link = document.createElement('a')
      link.href = response.data
      link.download = `export-${+new Date()}.pdf`
      link.target = '_blank'
      link.click()
    })
  }

  const printCompletePDF = (uuid: string) => {
    server.printCompletePDF(uuid).then((response) => {
      const link = document.createElement('a')
      link.href = response.data
      link.download = `export-${+new Date()}.pdf`
      link.target = '_blank'
      link.click()
    });
  }

  return (
    <>
      <SessionStatusDialog
        uuid={sessionUuid}
        show={showStatus}
        item={session}
        handleClose={() => setShowStatus(false)}
      />
      <SessionEditDialog uuid={sessionUuid} show={showEdit} handleClose={() => setShowEdit(false)} />
      <SessionDeleteDialog
        uuid={sessionUuid}
        show={showDelete}
        handleClose={() => setShowDelete(false)}
      />
      <div className='card card-xl-stretch mb-xl-12'>
        <div className='me-5 me-md-10 pt-5 flex-grow-1 flex-lg-grow-0 text-center'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logo-assempece.png')}
            className='logo-default h-150px'
            style={{ maxWidth: document.body.clientWidth - 40 }}
          />
        </div>
        <div className="separator my-5"></div>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-1 mt-1'>
              <KTSVG
                path='/media/icons/duotune/general/gen025.svg'
                className='svg-icon svg-icon-4x svg-icon-primary'
              />
            </div>
            <div className='flex-grow-1'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3'>Sessões</span>
                <span className='text-muted fw-bold fs-7'>Gerenciamento de todas as sessões</span>
              </h3>
            </div>
          </div>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-light-primary'
              onClick={() => {
                setSessionUuid('')
                setShowEdit(true)
              }}
            >
              Incluir
              <i className='fas fa-th-large ms-3 fs-2'></i>
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Filter */}
          <div className='d-flex flex-wrap my-10'>
            <div className='flex-grow-1 me-2'>
              <input
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
                className='form-control form-control-solid'
                placeholder='Filtre sessões aqui'
              />
            </div>
            <div className='mt-5 mt-md-0'>
              <button
                type='button'
                onClick={applyFilter}
                className='btn btn-light-success float-right me-2'
              >
                <i className='fas fa-filter fs-4'></i>
                Filtrar
              </button>
              <button
                type='button'
                onClick={removeFilter}
                className='btn btn-light-danger float-right'
              >
                <i className='fas fa-times fs-4'></i>
                Cancelar
              </button>
            </div>
          </div>
          {/* end::Filter */}
          <div className='separator separator-dashed border-gray-300 border-1 mt-3'></div>
          {/* begin::DataTable */}
          <div className=''>
            <div className='table-responsive'>
              {/* begin::Table container */}
              {/* begin::Table */}
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead className='border-gray-300'>
                  <tr className='fw-bolder text-muted border-gray-400 '>
                    <th className='col-1'>Data</th>
                    <th className='col-4'>Descrição</th>
                    <th className='col-1'>Tipo</th>
                    <th className='col-1'>Status</th>
                    <th className='col-1 text-center'>Ações</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody className='border-gray-300'>
                  {entities
                    ? entities.map((item: SessionModel) => (
                      <tr key={item.uuid}>
                        <td className='text-dark fw-bolder text-hover-primary fs-6'>
                          {item?.date}
                          {/* <FormatDate date={item?.date} /> */}
                        </td>
                        <td className='text-dark fw-bolder text-hover-primary fs-6'>{item?.name}</td>
                        <td className='text-dark fw-bolder text-hover-primary fs-6'>{item?.type}</td>
                        <td className='text-dark fw-bolder text-hover-primary fs-6'>
                          <span className={`badge badge-light-${buttonByStatus(item?.status ? item?.status : '')}`}>{item?.status}</span>
                        </td>
                        <td className='text-start'>
                          {changeStatusPermission(item) ?
                            (
                              <button
                                type='button'
                                data-toggle="tooltip" title="Alterar status"
                                className={`btn btn-icon btn-light-${buttonByStatus(item?.status ? item?.status : '')} me-1 mt-1 mt-md-0`}
                                onClick={() => {
                                  setSession(item)
                                  setShowStatus(true)
                                }}
                                style={{ width: 30, height: 30 }}
                              >
                                <i className='fas fa-check fs-4'></i>
                              </button>
                            ) : null}
                          <button
                            type='button'
                            data-toggle="tooltip" title="Pautas"
                            className='me-1 btn btn-icon btn-light-primary me-1 mt-1 mt-md-0'
                            onClick={() => goToTopics(item)}
                            style={{ width: 30, height: 30 }}
                          >
                            <Link
                              to='/sessions/topic'
                              className='font-weight-bold ml-2'
                              id='kt_login_signup'
                            >
                              <i className='fas fa-list fs-4'></i>
                            </Link>
                          </button>
                          {item.status === SessionStatus.ACTIVE.value ?
                            (
                              <>
                                <button
                                  type='button'
                                  data-toggle="tooltip" title="Editar"
                                  className='me-1 btn btn-icon btn-light-primary me-1 mt-1 mt-md-0'
                                  style={{ width: 30, height: 30 }}
                                  onClick={() => {
                                    setSessionUuid(item.uuid!)
                                    setShowEdit(true)
                                  }}
                                >
                                  <i className='fas fa-pen fs-4'></i>
                                </button>
                                <button
                                  type='button'
                                  data-toggle="tooltip" title="Excluir"
                                  className='me-1 btn btn-icon btn-light-danger me-1 mt-1 mt-md-0'
                                  style={{ width: 30, height: 30 }}
                                  onClick={() => {
                                    setShowDelete(true)
                                    setSessionUuid(item.uuid!)
                                  }}
                                >
                                  <i className='fas fa-trash fs-4'></i>
                                </button>
                              </>
                            ) : null
                          }
                          {item.status !== SessionStatus.ACTIVE.value && item.status !== SessionStatus.STARTED.value ?
                            (
                              <>
                                <button
                                  data-toggle="tooltip" title="Relatório Simples"
                                  className='me-1 btn btn-icon btn-light-info me-1 mt-1 mt-md-0'
                                  style={{ width: 30, height: 30 }}
                                  onClick={() => printSimplePDF(item.uuid!)}
                                >
                                  <i className='fa fa-file-pdf fs-4'></i>
                                </button>
                                <button
                                  data-toggle="tooltip" title="Relatório Completo"
                                  className='me-1 btn btn-icon btn-light-info'
                                  style={{ width: 30, height: 30 }}
                                  onClick={() => printCompletePDF(item.uuid!)}
                                >
                                  <i className='fa fa-file-pdf fs-4'></i>
                                </button>
                              </>
                            ) : null
                          }
                        </td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </table>
              {/* begin::Pagination */}
              <Pagination curPage={curPage} firstPage={firstPage} lastPage={lastPage} page={page} setPage={setPage} />
              {/* end::Pagination */}
            </div>
          </div>
          {/* end::DataTable */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export { SessionCard };

