/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import socketIOClient from "socket.io-client";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { SessionStatus } from "../../utils/SessionStatus";
import { TopicStatus } from "../../utils/TopicStatus";
import Pagination from "../components/Pagination";
import { OptionsDialog } from "../options/OptionsDialog";
import { VoteModel } from "../vote/models/VoteModel";
import { TopicModel } from "./models/TopicModel";
import * as actions from "./redux/topicsActions";
import * as server from "./redux/topicsCrud";
import { TopicDeleteDialog } from "./TopicDeleteDialog";
import { TopicEditDialog } from "./TopicEditDialog";
import { TopicEditStatusDialog } from "./TopicEditStatusDialog";

const ENDPOINT = process.env.SERVER_API_URL || "https://api.assempece.org.br";

const TopicCard = () => {
  const [showOption, setShowOption] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEditStatus, setShowEditStatus] = useState(false);
  const [topicUuid, setTopicUuid] = useState("");
  const [topic, setTopic] = useState<TopicModel>();
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");

  const history = useHistory();

  const { currentState, sessionState } = useSelector(
    (state: any) => ({
      currentState: state.topic,
      sessionState: state.session,
    }),
    shallowEqual
  );
  const { actualSession } = sessionState;
  const { entities, firstPage, lastPage, curPage, topicStart } = currentState;

  const applyGeneralFilter = (page: number, name: string) => {
    if (actualSession) {
      const obj = { page: page, name: name, parentUuid: actualSession.uuid };
      dispatch(actions.fetchTopics(obj));
    } else {
      history.push("/sessions");
    }
  };

  const openOrStartTopic = () => {
    if (actualSession) {
      const obj = {
        page: 1,
        name: "",
        parentUuid: actualSession.uuid,
        status: "Iniciada",
      };
      dispatch(actions.fetchOpenOrStartTopics(obj));
    } else {
      history.push("/sessions");
    }
  };

  const applyFilter = () => {
    applyGeneralFilter(1, name);
  };

  const removeFilter = () => {
    setName("");
    applyGeneralFilter(1, "");
  };

  const getTopicResult = (e: TopicModel) => {
    if (e?.votes.length === 0) {
      return "Sem votação";
    }
    let result: { qnt: number; option: any }[] = [];
    if (e.options) {
      e.options.forEach((o: any) => {
        result.push({
          qnt: e?.votes.filter((v) => v.option?.uuid === o.uuid).length,
          option: o,
        });
      });
      result.sort((a, b) => (b.qnt > a.qnt) ? 1 : ((a.qnt > b.qnt) ? -1 : 0))
      if (result[0].qnt === result[1].qnt) {
        return "Empate"
      }
      return result[0].option.name;
    }
    return "Sem votação";
  };

  const buttonByStatus = (status: string) => {
    switch (status) {
      case TopicStatus.ACTIVE.value:
        return "success";

      case TopicStatus.OPENED.value:
        return "primary";

      case TopicStatus.STARTED.value:
        return "primary";

      default:
        return "warning";
    }
  };

  const printPDF = (uuid: string) => {
    server.printPDF(uuid).then((response) => {
      const link = document.createElement("a");
      link.href = response.data;
      link.download = `export-${+new Date()}.pdf`;
      link.target = "_blank";
      link.click();
    });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    applyGeneralFilter(page, name);
    openOrStartTopic();
    const socket = socketIOClient(ENDPOINT);
    socket.on("index:topicFinished", (data) => {
      if (!data) {
        applyGeneralFilter(page, name);
        openOrStartTopic();
      }
    });
  }, [dispatch, page, showOption, showEdit, showDelete, showEditStatus]);

  return (
    <>
      <OptionsDialog
        topicId={topicUuid}
        topic={topic}
        show={showOption}
        handleClose={() => setShowOption(false)}
      />
      <TopicDeleteDialog
        uuid={topicUuid}
        show={showDelete}
        handleClose={() => setShowDelete(false)}
      />
      <TopicEditDialog
        topic={topic}
        parentUuid={actualSession ? actualSession.uuid : ""}
        show={showEdit}
        handleClose={() => setShowEdit(false)}
      />
      <TopicEditStatusDialog
        obj={topic}
        existOpenOrStart={topicStart}
        show={showEditStatus}
        handleClose={() => setShowEditStatus(false)}
      />
      <div className="card card-xl-stretch mb-xl-12">
        <div className='me-5 me-md-10 pt-5 flex-grow-1 flex-lg-grow-0 text-center'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logo-assempece.png')}
            className='logo-default h-150px'
            style={{ maxWidth: document.body.clientWidth - 40 }}
          />
        </div>
        <div className="separator my-5"></div>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-1 mt-1">
              <KTSVG
                path="/media/icons/duotune/general/gen019.svg"
                className="svg-icon svg-icon-4x svg-icon-primary"
              />
            </div>
            <div className="flex-grow-1">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3">Pautas</span>
                <span className="text-muted fw-bold fs-7">
                  Gerenciamento de todas as pautas
                </span>
              </h3>
            </div>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-light-primary mx-2"
              onClick={() => {
                setTopic(undefined);
                setShowEdit(true);
              }}
            >
              Incluir
              <i className="fas fa-list ms-3 fs-2"></i>
            </button>
            <button
              type="button"
              onClick={() => history.goBack()}
              className="btn btn-active-secondary"
            >
              Voltar
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* begin::Filter */}
          <div className="d-flex flex-wrap my-10">
            <div className="flex-grow-1 me-2">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control form-control-solid"
                placeholder="Filtre pautas aqui"
              />
            </div>
            <div className="mt-5 mt-md-0">
              <button
                type="button"
                onClick={applyFilter}
                className="btn btn-light-success float-right me-2"
              >
                <i className="fas fa-filter fs-4"></i>
                Filtrar
              </button>
              <button
                type="button"
                onClick={removeFilter}
                className="btn btn-light-danger float-right"
              >
                <i className="fas fa-times fs-4"></i>
                Cancelar
              </button>
            </div>
          </div>
          {/* end::Filter */}
          {/* begin::DataTable */}
          <div className="text-gray-700 fw-bolder text-hover-primary fs-1 pt-2 align-center text-center">
            Pauta em curso
          </div>
          <div className="separator separator-dashed border-gray-300 border-1 mt-3"></div>
          {topicStart ? (
            <div className="">
              <div className="table-responsive">
                <table className="table table-row-bordered table-row-gray-100 align-middle gs-2 gy-3 table-striped">
                  <thead className="border-gray-300 px-3">
                    <tr className="fw-bolder text-muted border-gray-400">
                      <th className="col-4 text-success">Assunto</th>
                      <th className="col-1 text-success">Secreto</th>
                      <th className="col-1 text-success">Resultado</th>
                      <th className="col-1 text-success">Status</th>
                      <th className="col-1 text-success text-center">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-gray-300">
                    <tr key={`election-${topicStart.uuid}`}>
                      <td className="text-success fw-bolder text-hover-primary fs-6">
                        {topicStart?.name}
                        {/* <FormatDate date={item?.date} /> */}
                      </td>
                      <td className="text-muted fw-bold fs-6">
                        {topicStart?.secret_vote}
                      </td>
                      <td className="text-muted fw-bold fs-6">
                        {getTopicResult(topicStart)}
                      </td>
                      <td className="text-muted fw-bold fs-6">
                        <span
                          className={`badge badge-light-${buttonByStatus(
                            topicStart?.status ? topicStart?.status : ""
                          )}`}
                        >
                          {topicStart?.status}
                        </span>
                      </td>
                      <td className="text-center align-middle">
                        {topicStart?.options?.length >
                          (topicStart.is_multiple
                            ? topicStart.max_vote !== null
                              ? topicStart.max_vote
                              : 1
                            : 1) ||
                          topicStart.status !== TopicStatus.ACTIVE.value ? (
                          <button
                            type="button"
                            className="btn btn-icon btn-light-primary me-1 mt-1 mt-md-0"
                            style={{ width: 30, height: 30 }}
                            onClick={() => {
                              setTopic(topicStart);
                              setTopicUuid(topicStart.uuid);
                              setShowEditStatus(true);
                            }}
                            data-toggle="tooltip"
                            title="Alterar Status"
                          >
                            <i className="fa fa-retweet fs-4"></i>
                          </button>
                        ) : null}
                        <button
                          type="button"
                          className="btn btn-icon btn-light-primary me-1 mt-1 mt-md-0"
                          style={{ width: 30, height: 30 }}
                          onClick={() => {
                            setTopic(topicStart);
                            setTopicUuid(topicStart.uuid);
                            setShowOption(true);
                          }}
                          data-toggle="tooltip"
                          title="Opções"
                        >
                          <i className="fas fa-list fs-4"></i>
                        </button>
                        {topicStart?.status === TopicStatus.ACTIVE.value ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-icon btn-light-primary me-1 mt-1 mt-md-0"
                              style={{ width: 30, height: 30 }}
                              onClick={() => {
                                setTopic(topicStart);
                                setTopicUuid(topicStart.uuid);
                                setShowEdit(true);
                              }}
                              data-toggle="tooltip"
                              title="Editar"
                            >
                              <i className="fas fa-pen fs-4"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-icon btn-light-danger me-1 mt-1 mt-md-0"
                              style={{ width: 30, height: 30 }}
                              onClick={() => {
                                setTopicUuid(topicStart.uuid);
                                setShowDelete(true);
                              }}
                              data-toggle="tooltip"
                              title="Excluir"
                            >
                              <i className="fas fa-trash fs-4"></i>
                            </button>
                          </>
                        ) : null}
                        {topicStart?.status !== TopicStatus.ACTIVE.value &&
                          topicStart?.status !== TopicStatus.STARTED.value ? (
                          <>
                            <button
                              className="btn btn-icon btn-light-info me-1 mt-1 mt-md-0"
                              style={{ width: 30, height: 30 }}
                              onClick={() => printPDF(topicStart.uuid!)}
                              data-toggle="tooltip"
                              title="Relatório de Pauta"
                            >
                              <i className="fas fa-file fs-4"></i>
                            </button>
                          </>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="text-success fw-bolder text-hover-primary fs-6 text-center">
              <span>Não há pauta em destaque no momento</span>
            </div>
          )}
          {/* end::DataTable */}
          <div className="separator separator-dashed border-gray-300 border-1 mt-3"></div>
          <div className="text-gray-700 fw-bolder text-hover-primary fs-1 pt-2 align-center text-center">
            Demais Pautas
          </div>
          <div className="separator separator-dashed border-gray-300 border-1 mt-3"></div>
          {/* begin::DataTable */}
          <div className="">
            <div className="table-responsive">
              {/* begin::Table container */}
              {/* begin::Table */}
              <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                <thead className="border-gray-300">
                  <tr className="fw-bolder text-muted border-gray-400">
                    <th className="col-4">Assunto</th>
                    <th className="col-1">Secreto</th>
                    <th className="col-1">Resultado</th>
                    <th className="col-1">Status</th>
                    <th className="col-1 text-center">Ações</th>
                  </tr>
                </thead>
                <tbody className="border-gray-300">
                  {entities
                    ? entities.map((item: TopicModel) => (
                      <tr key={item.uuid}>
                        <td className="text-dark fw-bolder text-hover-primary fs-6">
                          {item?.name}
                        </td>
                        <td className="text-dark fw-bolder text-hover-primary fs-6">
                          {item?.secret_vote}
                        </td>
                        <td className="text-dark fw-bolder text-hover-primary fs-6">
                          {getTopicResult(item)}
                        </td>
                        <td className="text-dark fw-bolder text-hover-primary fs-6">
                          <span
                            className={`badge badge-light-${buttonByStatus(
                              item?.status ? item?.status : ""
                            )}`}
                          >
                            {item?.status}
                          </span>
                        </td>
                        <td className="text-start">
                          {actualSession?.status ===
                            SessionStatus.STARTED.value ? (
                            <button
                              type="button"
                              className="btn btn-icon btn-light-primary me-1 mt-1 mt-md-0"
                              onClick={() => {
                                setTopic(item);
                                setTopicUuid(item.uuid!);
                                setShowEditStatus(true);
                              }}
                              style={{ width: 30, height: 30 }}
                              data-toggle="tooltip"
                              title="Alterar Status"
                            >
                              <i className="fa fa-retweet fs-4"></i>
                            </button>
                          ) : null}
                          <button
                            type="button"
                            className="btn btn-icon btn-light-primary me-1 mt-1 mt-md-0"
                            style={{ width: 30, height: 30 }}
                            onClick={() => {
                              setTopic(item);
                              setTopicUuid(item.uuid!);
                              setShowOption(true);
                            }}
                            data-toggle="tooltip"
                            title="Opções"
                          >
                            <i className="fas fa-list fs-4"></i>
                          </button>
                          {item.status === TopicStatus.ACTIVE.value ||
                            item.status === TopicStatus.OPENED.value ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-icon btn-light-primary me-1 mt-1 mt-md-0"
                                style={{ width: 30, height: 30 }}
                                onClick={() => {
                                  setTopic(item);
                                  setTopicUuid(item.uuid!);
                                  setShowEdit(true);
                                }}
                                data-toggle="tooltip"
                                title="Editar"
                              >
                                <i className="fas fa-pen fs-4"></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-icon btn-light-danger me-1 mt-1 mt-md-0"
                                style={{ width: 30, height: 30 }}
                                onClick={() => {
                                  setTopicUuid(item.uuid!);
                                  setShowDelete(true);
                                }}
                                data-toggle="tooltip"
                                title="Excluir"
                              >
                                <i className="fas fa-trash fs-4"></i>
                              </button>
                            </>
                          ) : null}
                          {item.status !== TopicStatus.ACTIVE.value &&
                            item.status !== TopicStatus.OPENED.value &&
                            item.status !== TopicStatus.STARTED.value ? (
                            <button
                              className="btn btn-icon btn-light-info me-1 mt-1 mt-md-0"
                              style={{ width: 30, height: 30 }}
                              onClick={() => printPDF(item.uuid!)}
                              data-toggle="tooltip"
                              title="Relatório de Pauta"
                            >
                              <i className="fas fa-file fs-4"></i>
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </table>
              {!entities ? (
                <div className="text-success fw-bolder text-hover-primary fs-6 text-center">
                  <span>Não há pautas no momento</span>
                </div>
              ) : null}
              {/* begin::Pagination */}
              <Pagination
                curPage={curPage}
                firstPage={firstPage}
                lastPage={lastPage}
                page={page}
                setPage={setPage}
              />
              {/* end::Pagination */}
            </div>
          </div>
          {/* end::DataTable */}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
};

export { TopicCard };

