import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useDispatch } from 'react-redux'
import { afterCloseStatus, afterStartStatus, ElectionStatus, initialStatus } from '../../utils/ElectionStatus'
import { TimeToVote } from '../../utils/TimeToVote'
import { ElectionModel } from './models/ElectionModel'
import * as actions from './redux/electionsActions'

export interface ElectionEditProps {
  obj: ElectionModel | undefined
  show: boolean
  handleClose: () => void
}

export function ElectionEditStatusDialog(props: ElectionEditProps) {
  const [error, setError] = useState(false)
  const [status, setStatus] = useState<any[]>([])
  const [time, setTime] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const dispatch: any = useDispatch()

  const getStatusEnum = async () => {
    switch (props.obj?.status) {
      case ElectionStatus.ACTIVE.value:
        setStatus(initialStatus)
        break

      case ElectionStatus.STARTED.value:
        setStatus(afterStartStatus)
        break

      default:
        setStatus(afterCloseStatus)
        break
    }
  }

  const updateElection = () => {
    let copy = Object.assign({}, props.obj)
    copy.status = selectedStatus
    copy.duration = Number(time)
    dispatch(actions.changeStatus(copy)).then((response: any) => {
      if (response) {
        setSelectedStatus('')
        setError(false)
        props.handleClose()
      } else {
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 2000)
      }
    })
  }

  useEffect(() => {
    getStatusEnum()
  }, [props])

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header >
          <Modal.Title>Gerenciar Eleição</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? (
            <div className='mb-10' style={{ textAlign: 'center', borderRadius: 10 }}>
              <label className='form-label' style={{ color: 'red' }}>
                Já existe uma eleição iniciada, aguarde o encerramento!
              </label>
            </div>
          ) : null}
          <div className='mb-5' style={{ textAlign: 'left' }}>
            <label className='form-label'>Selecione uma ação:</label>
            <select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              className='form-select form-select-solid'
              aria-label='Status'
              placeholder='Selecione um status'
            >
              <option key='option-selecione-status'>Selecione...</option>
              {status?.map((s) => {
                return (
                  <option key={`status-${s.value}`} value={s.value}>
                    {s.text}
                  </option>
                )
              })}
            </select>
          </div>
          {selectedStatus === ElectionStatus.REACTIVE.value ? (
            <div style={{ textAlign: 'center', borderRadius: 10 }}>
              <label className='form-label' style={{ color: 'red' }}>
                Tem certeza que deseja reativar esta eleição? Todos os votos serão excluídos!
              </label>
            </div>
          ) : null}
          {
            selectedStatus === ElectionStatus.STARTED.value ? (
              <>
                <div className='mb-10' style={{ textAlign: 'left' }}>
                  <label className='form-label'>Selecione uma tempo para votação:</label>
                  <select
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    className='form-select form-select-solid'
                    aria-label='Status'
                    placeholder='Selecione um status'
                  >
                    {Object.values(TimeToVote).map((t) => {
                      return <option value={t.value}>{t.text}</option>
                    })}
                  </select>
                </div>
              </>
            ) : null
          }
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-light'
            onClick={() => {
              setError(false)
              setSelectedStatus('')
              props.handleClose()
            }}
          >
            Fechar
          </button>
          <button type='button' className='btn btn-primary' onClick={updateElection}>
            Salvar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
