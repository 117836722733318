export let TopicStatus = {
  ACTIVE: {text: 'Ativa', value: 'Ativa'},
  OPENED: {text: 'Aberta para discussão', value: 'Aberta para discussão'},
  STARTED: {text: 'Iniciar Votação', value: 'Iniciada'},
  CLOSED: {text: 'Encerrar votação', value: 'Encerrada'},
  POSTPONED: {text: 'Adiar pauta', value: 'Adiada'},
  REACTIVE: {text: 'Reativar pauta', value: 'Ativa'},
}

export let initialStatus = [TopicStatus.OPENED, TopicStatus.CLOSED, TopicStatus.POSTPONED]

export let afterOpenStatus = [TopicStatus.STARTED, TopicStatus.CLOSED, TopicStatus.POSTPONED]

export let afterStartStatus = [TopicStatus.CLOSED, TopicStatus.POSTPONED]

export let afterCloseStatus = [TopicStatus.REACTIVE]
