import {Field, Form, Formik} from 'formik'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {RootState} from '../../../setup'
import {UserModel} from './models/UserModel'
import * as actions from './redux/usersActions'

export interface UserFormProps {
  handleClose: () => void
}

export function PasswordChangeForm(props: UserFormProps) {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const PasswordChangeSchema = Yup.object().shape({
    password: Yup.string().required('Senha é obrigatória.'),
    confirmPassword: Yup.string().when('password', {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Campos não coincidem'),
    }),
  })
  const dispatch: any = useDispatch()

  const saveUser = (obj: UserModel) => {
    dispatch(actions.changePasswordUser(user.uuid!, obj.password!)).then(() => {
      props.handleClose()
    })
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={user}
        validationSchema={PasswordChangeSchema}
        onSubmit={(values) => {
          saveUser(values)
        }}
      >
        {({handleSubmit, handleChange, handleBlur, values, errors, touched}) => (
          <>
            <Form className='form form-label-right'>
              <div className='modal-body'>
                <div className='mb-10' style={{textAlign: 'left'}}>
                  <label className='form-label'>Senha</label>
                  <Field
                    type='password'
                    autoComplete='off'
                    name='password'
                    className='form-control form-control-solid'
                    placeholder='Senha'
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.password && touched.password ? (
                    <span style={{color: 'red'}}>{errors.password}</span>
                  ) : null}
                </div>
                <div className='mb-10' style={{textAlign: 'left'}}>
                  <label className='form-label'>Confirme a Senha</label>
                  <Field
                    type='password'
                    autoComplete='off'
                    name='confirmPassword'
                    className='form-control form-control-solid'
                    placeholder='Confirme a Senha'
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <span style={{color: 'red'}}>{errors.confirmPassword}</span>
                  ) : null}
                </div>
              </div>
            </Form>
            <div className='modal-footer'>
              <button
                id='modal-close-button'
                type='button'
                className='btn btn-light'
                onClick={props.handleClose}
              >
                Fechar
              </button>
              <button type='button' className='btn btn-primary' onClick={() => handleSubmit()}>
                Salvar
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  )
}
