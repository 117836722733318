/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { ElectionStatus } from "../../utils/ElectionStatus";
import Pagination from "../components/Pagination";
import { OptionsDialog } from "../options/OptionsDialog";
import { ElectionDeleteDialog } from "./ElectionDeleteDialog";
import { ElectionEditDialog } from "./ElectionEditDialog";
import { ElectionEditStatusDialog } from "./ElectionEditStatusDialog";
import { ElectionModel } from "./models/ElectionModel";
import * as actions from "./redux/electionsActions";
import * as server from "./redux/electionsCrud";

const ENDPOINT = process.env.SERVER_API_URL || "https://api.assempece.org.br";

const ElectionCard = () => {
  const [showOption, setShowOption] = useState(false);
  const [showEditStatus, setShowEditStatus] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [electionUuid, setElectionUuid] = useState<string | null>("");
  const [election, setElection] = useState<ElectionModel>();
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");

  const { currentState } = useSelector(
    (state: any) => ({
      currentState: state.election,
    }),
    shallowEqual
  );
  const { entities, firstPage, lastPage, curPage, entityStart } = currentState;

  const applyGeneralFilter = (page: number, name: string) => {
    const obj = { page: page, name: name };
    dispatch(actions.fetchElections(obj));
  };

  const electionStart = () => {
    dispatch(actions.electionStart());
  };

  const applyFilter = () => {
    applyGeneralFilter(1, name);
  };

  const removeFilter = () => {
    setName("");
    applyGeneralFilter(1, "");
  };

  const getElectionResult = (e: ElectionModel) => {
    if (e?.votes.length === 0) {
      return "Sem votação";
    }
    let result: { qnt: number; option: any }[] = [];
    if (e.options) {
      e.options.forEach((o) => {
        result.push({
          qnt: e?.votes.filter((v) => v.option?.uuid === o.uuid).length,
          option: o,
        });
      });
      result.sort((a, b) => (b.qnt > a.qnt) ? 1 : ((a.qnt > b.qnt) ? -1 : 0))
      if (result && result.length) {
        if (result[0].qnt === result[1].qnt) {
          return "Empate"
        }
        return result[0].option.name;
      }
    }
    return "Sem votação";
  };

  const printPDF = (uuid: string) => {
    server.printPDF(uuid).then((response) => {
      const link = document.createElement("a");
      link.href = response.data;
      link.download = `export-${+new Date()}.pdf`;
      link.target = "_blank";
      link.click();
    });
  };

  const buttonByStatus = (status: string) => {
    switch (status) {
      case ElectionStatus.ACTIVE.value:
        return "success";

      case ElectionStatus.STARTED.value:
        return "primary";

      default:
        return "danger";
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    applyGeneralFilter(page, name);
    electionStart();
    const socket = socketIOClient(ENDPOINT);
    socket.on("index:electionFinished", (data) => {
      if (!data) {
        applyGeneralFilter(page, name);
        electionStart();
      }
    });
  }, [dispatch, page, showOption, showEdit, showDelete, showEditStatus]);

  return (
    <>
      <OptionsDialog
        topicId={electionUuid}
        topic={election}
        show={showOption}
        handleClose={() => setShowOption(false)}
      />
      <ElectionEditDialog
        topic={election}
        show={showEdit}
        handleClose={() => setShowEdit(false)}
      />
      <ElectionDeleteDialog
        uuid={electionUuid!}
        show={showDelete}
        handleClose={() => setShowDelete(false)}
      />
      <ElectionEditStatusDialog
        obj={election}
        show={showEditStatus}
        handleClose={() => setShowEditStatus(false)}
      />
      <div className="card card-xl-stretch mb-xl-12">
        <div className='me-5 me-md-10 pt-5 flex-grow-1 flex-lg-grow-0 text-center'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logo-assempece.png')}
            className='logo-default h-150px'
            style={{ maxWidth: document.body.clientWidth - 40 }}
          />
        </div>
        <div className="separator my-5"></div>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-1 mt-1">
              <KTSVG
                path="/media/icons/duotune/general/gen032.svg"
                className="svg-icon svg-icon-4x svg-icon-primary"
              />
            </div>
            <div className="flex-grow-1">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3">Eleições</span>
                <span className="text-muted fw-bold fs-7">
                  Gerenciamento de todas as eleições
                </span>
              </h3>
            </div>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-light-primary"
              onClick={() => {
                setElection(undefined);
                setShowEdit(true);
              }}
            >
              Incluir
              <i className="far fa-chart-bar ms-3 fs-2"></i>
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* begin::Filter */}
          <div className="d-flex flex-wrap my-10">
            <div className="flex-grow-1 me-2">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control form-control-solid"
                placeholder="Filtre as eleições aqui"
              />
            </div>
            <div className="mt-5 mt-md-0">
              <button
                type="button"
                onClick={applyFilter}
                className="btn btn-light-success float-right me-2"
              >
                <i className="fas fa-filter fs-4"></i>
                Filtrar
              </button>
              <button
                type="button"
                onClick={removeFilter}
                className="btn btn-light-danger float-right"
              >
                <i className="fas fa-times fs-4"></i>
                Cancelar
              </button>
            </div>
          </div>
          {/* end::Filter */}
          <div className="separator separator-dashed border-gray-300 border-1 mt-3"></div>
          <div className="text-gray-700 fw-bolder text-hover-primary fs-1 pt-2 align-center text-center">
            Eleição em curso
          </div>
          <div className="separator separator-dashed border-gray-300 border-1 mt-3"></div>
          {/* begin::DataTable */}
          {entityStart ? (
            <div className="">
              <div className="table-responsive">
                <table className="table table-row-bordered table-row-gray-100 align-middle gs-2 gy-3 table-striped">
                  <thead className="border-gray-300 px-3">
                    <tr className="fw-bolder text-muted border-gray-400">
                      <th className="col-3 text-success">Assunto</th>
                      <th className="col-3 text-success">Secreto</th>
                      <th className="col-3 text-success">Resultado</th>
                      <th className="col-3 text-success">Status</th>
                      <th className="col-3 text-success text-center">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-gray-300">
                    {entityStart ? (
                      <tr key={`election-${entityStart.uuid}`}>
                        <td className="text-success fw-bolder text-hover-primary fs-6">
                          {entityStart?.name}
                          {/* <FormatDate date={item?.date} /> */}
                        </td>
                        <td className="text-muted fw-bold fs-6">
                          {entityStart?.secret_vote}
                        </td>
                        <td className="text-muted fw-bold fs-6">
                          {getElectionResult(entityStart)}
                        </td>
                        <td className="text-muted fw-bold fs-6">
                          <span
                            className={`badge badge-light-${buttonByStatus(
                              entityStart?.status ? entityStart?.status : ""
                            )}`}
                          >
                            {entityStart?.status}
                          </span>
                        </td>
                        <td className="text-center align-middle">
                          {entityStart?.options?.length >
                            (entityStart.is_multiple
                              ? entityStart.max_vote !== null
                                ? entityStart.max_vote
                                : 1
                              : 1) ||
                            entityStart.status !== ElectionStatus.ACTIVE.value ? (
                            <button
                              type="button"
                              className="btn btn-icon btn-light-primary me-1 mt-1 mt-md-0"
                              style={{ width: 30, height: 30 }}
                              onClick={() => {
                                setElection(entityStart);
                                setElectionUuid(entityStart.uuid);
                                setShowEditStatus(true);
                              }}
                              data-toggle="tooltip"
                              title="Alterar Status"
                            >
                              <i className="fa fa-retweet fs-4"></i>
                            </button>
                          ) : null}
                          <button
                            type="button"
                            className="btn btn-icon btn-light-primary me-1 mt-1 mt-md-0"
                            style={{ width: 30, height: 30 }}
                            onClick={() => {
                              setElection(entityStart);
                              setElectionUuid(entityStart.uuid);
                              setShowOption(true);
                            }}
                            data-toggle="tooltip"
                            title="Opções"
                          >
                            <i className="fas fa-list fs-4"></i>
                          </button>
                          {entityStart?.status === ElectionStatus.ACTIVE.value ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-icon btn-light-primary me-1 mt-1 mt-md-0"
                                style={{ width: 30, height: 30 }}
                                onClick={() => {
                                  setElection(entityStart);
                                  setShowEdit(true);
                                }}
                                data-toggle="tooltip"
                                title="Editar"
                              >
                                <i className="fas fa-pen fs-4"></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-icon btn-light-danger me-1 mt-1 mt-md-0"
                                style={{ width: 30, height: 30 }}
                                onClick={() => {
                                  setElectionUuid(entityStart.uuid);
                                  setShowDelete(true);
                                }}
                                data-toggle="tooltip"
                                title="Excluir"
                              >
                                <i className="fas fa-trash fs-4"></i>
                              </button>
                            </>
                          ) : null}
                          {entityStart?.status !== ElectionStatus.ACTIVE.value &&
                            entityStart?.status !== ElectionStatus.STARTED.value ? (
                            <>
                              <button
                                className="btn btn-icon btn-light-info me-1 mt-1 mt-md-0"
                                style={{ width: 30, height: 30 }}
                                onClick={() => printPDF(entityStart.uuid!)}
                                data-toggle="tooltip"
                                title="Relatório da Eleição"
                              >
                                <i className="fas fa-file fs-4"></i>
                              </button>
                            </>
                          ) : null}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="text-success fw-bolder text-hover-primary fs-6 text-center">
              <span>Não há eleição em destaque no momento</span>
            </div>
          )}
          {/* end::DataTable */}
          <div className="separator separator-dashed border-gray-300 border-1 mt-3"></div>
          <div className="text-gray-700 fw-bolder text-hover-primary fs-1 pt-2 align-center text-center">
            Demais Eleições
          </div>
          <div className="separator separator-dashed border-gray-300 border-1 mt-3"></div>
          {/* begin::DataTable */}
          <div className="">
            <div className="table-responsive">
              <table className="table table-row-bordered table-row-gray-100 align-middle gs-2 gy-3 table-striped">
                <thead className="border-gray-300 px-3">
                  <tr className="fw-bolder text-muted border-gray-400">
                    <th className="col-3">Assunto</th>
                    <th className="col-1">Secreto</th>
                    <th className="col-3">Resultado</th>
                    <th className="col-2">Status</th>
                    <th className="col-3 text-center">Ações</th>
                  </tr>
                </thead>
                <tbody className="border-gray-300">
                  {entities
                    ? entities.map((item: ElectionModel) => (
                      <tr key={`election-${item.uuid}`}>
                        <td className="text-dark fw-bolder text-hover-primary fs-6">
                          {item?.name}
                          {/* <FormatDate date={item?.date} /> */}
                        </td>
                        <td className="text-muted fw-bold fs-6">
                          {item?.secret_vote}
                        </td>
                        <td className="text-muted fw-bold fs-6">
                          {getElectionResult(item)}
                        </td>
                        <td className="text-muted fw-bold fs-6">
                          {item?.status}
                        </td>
                        <td className="text-center align-middle">
                          {!entityStart ||
                            item?.status !== ElectionStatus.ACTIVE.value ? (
                            <button
                              type="button"
                              className="btn btn-icon btn-light-primary me-1 mt-1 mt-md-0"
                              style={{ width: 30, height: 30 }}
                              onClick={() => {
                                setElection(item);
                                setElectionUuid(item.uuid);
                                setShowEditStatus(true);
                              }}
                              data-toggle="tooltip"
                              title="Alterar Status"
                            >
                              <i className="fa fa-retweet fs-4"></i>
                            </button>
                          ) : null}
                          <button
                            type="button"
                            className="btn btn-icon btn-light-primary me-1 mt-1 mt-md-0"
                            style={{ width: 30, height: 30 }}
                            onClick={() => {
                              setElection(item);
                              setElectionUuid(item.uuid);
                              setShowOption(true);
                            }}
                            data-toggle="tooltip"
                            title="Opções"
                          >
                            <i className="fas fa-list fs-4"></i>
                          </button>
                          {item?.status === ElectionStatus.ACTIVE.value ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-icon btn-light-primary me-1 mt-1 mt-md-0"
                                style={{ width: 30, height: 30 }}
                                onClick={() => {
                                  setElection(item);
                                  setShowEdit(true);
                                }}
                                data-toggle="tooltip"
                                title="Editar"
                              >
                                <i className="fas fa-pen fs-4"></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-icon btn-light-danger me-1 mt-1 mt-md-0"
                                style={{ width: 30, height: 30 }}
                                onClick={() => {
                                  setElectionUuid(item.uuid);
                                  setShowDelete(true);
                                }}
                                data-toggle="tooltip"
                                title="Excluir"
                              >
                                <i className="fas fa-trash fs-4"></i>
                              </button>
                            </>
                          ) : null}
                          {item?.status !== ElectionStatus.ACTIVE.value &&
                            item?.status !== ElectionStatus.STARTED.value ? (
                            <>
                              <button
                                className="btn btn-icon btn-light-info me-1 mt-1 mt-md-0"
                                style={{ width: 30, height: 30 }}
                                onClick={() => printPDF(item.uuid!)}
                                data-toggle="tooltip"
                                title="Relatório da Eleição"
                              >
                                <i className="fas fa-file fs-4"></i>
                              </button>
                            </>
                          ) : null}
                        </td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </table>
              {!entities ? (
                <div className="text-success fw-bolder text-hover-primary fs-6 text-center">
                  <span>Não há eleições no momento</span>
                </div>
              ) : null}
              {/* begin::Pagination */}
              <Pagination
                curPage={curPage}
                firstPage={firstPage}
                lastPage={lastPage}
                page={page}
                setPage={setPage}
              />
              {/* end::Pagination */}
            </div>
          </div>
          {/* end::DataTable */}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
};

export { ElectionCard };

