import {Modal} from 'react-bootstrap-v5'
import {PasswordChangeForm} from './PasswordChangeForm'

export interface UserEditProps {
  show: boolean
  handleClose: () => void
}

export function UserMeChangePasswordDialog(props: UserEditProps) {
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>Alteração de senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PasswordChangeForm handleClose={props.handleClose}></PasswordChangeForm>
        </Modal.Body>
      </Modal>
    </>
  )
}
