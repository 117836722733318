import {Field, Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {TopicModel} from './models/TopicModel'
import * as actions from './redux/topicsActions'

export interface TopicFormProps {
  obj: TopicModel
  parentUuid: string
  handleClose: () => void
}

export function TopicEditForm(props: TopicFormProps) {
  const [isMulti, setIsMulti] = useState(false)

  const [standardChoice, setStandardChoice] = useState(false)
  const TopicEditSchema = Yup.object().shape({
    name: Yup.string().required('Assunto é obrigatório.'),
    is_multiple: Yup.boolean().optional(),
    standard_options: Yup.boolean().optional(),
    secret_vote: Yup.string().required('Voto secreto é obrigatório.'),
  })
  const dispatch: any = useDispatch()

  const saveTopic = (obj: any) => {
    obj.is_multiple = isMulti
    obj.standard_options = standardChoice
    obj.parentUuid = props.parentUuid
    if (!obj.uuid) {
      obj.status = 'Ativa'
      dispatch(actions.createTopic(obj)).then(() => {
        props.handleClose()
      })
    } else {
      dispatch(actions.updateTopic(obj)).then(() => {
        props.handleClose()
      })
    }
  }

  useEffect(() => {
    setIsMulti(props.obj?.is_multiple)
  }, [props.obj])

  return (
    <>
      {props.obj ? (
        <Formik
          enableReinitialize={true}
          initialValues={props.obj}
          validationSchema={TopicEditSchema}
          onSubmit={(values) => {
            saveTopic(values)
          }}
        >
          {({handleSubmit, setFieldValue, values, errors, touched}) => (
            <>
              <Form className='form form-label-right'>
                <Modal.Body>
                  <div className='mb-10' style={{textAlign: 'left'}}>
                    <label className='form-label'>Assunto</label>
                    <Field
                      type='text'
                      name='name'
                      className='form-control form-control-solid'
                      placeholder='Assunto'
                    />
                    {errors.name && touched.name ? (
                      <span style={{color: 'red'}}>{errors.name}</span>
                    ) : null}
                  </div>
                  <div className='mb-10' style={{textAlign: 'left'}}>
                    <label className='form-label'>Voto Secreto</label>
                    <Field
                      as='select'
                      name='secret_vote'
                      className='form-select form-select-solid'
                      aria-label='Select example'
                      placeholder='Voto Secreto'
                    >
                      <option>Selecione...</option>
                      <option value='Sim'>Sim</option>
                      <option value='Não'>Não</option>
                    </Field>
                    {errors.secret_vote && touched.secret_vote ? (
                      <span style={{color: 'red'}}>{errors.secret_vote}</span>
                    ) : null}
                  </div>
                  {!props.obj.uuid ? (
                    <div className='mb-10' style={{textAlign: 'left'}}>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        onChange={() => {
                          setStandardChoice(!standardChoice)
                        }}
                        id='standardChoice'
                      />
                      <label className='form-check-label px-3 text-solid' htmlFor='standardChoice'>
                        Criar opções de voto padrão (Sim, Não, Abstenção)?
                      </label>
                    </div>
                  ) : null}
                  <div className='mb-10' style={{textAlign: 'left'}}>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      checked={isMulti}
                      onChange={() => {
                        setFieldValue('is_multiple', !isMulti)
                        setIsMulti(!isMulti)
                      }}
                      id='isMulti'
                    />
                    <label className='form-check-label px-3' htmlFor='isMulti'>
                      A pauta possui múltipla escolha?
                    </label>
                  </div>
                  {isMulti ? (
                    <>
                      <div className='mb-10' style={{textAlign: 'left'}}>
                        <label className='form-label'>Número mínimo de escolha</label>
                        <Field
                          type='number'
                          name='min_vote'
                          className='form-control form-control-solid'
                          placeholder='Número mínimo de escolhas'
                          min='1'
                          max='250'
                        />
                      </div>
                      <div className='mb-10' style={{textAlign: 'left'}}>
                        <label className='form-label'>Número máximo de escolhas</label>
                        <Field
                          type='number'
                          name='max_vote'
                          className='form-control form-control-solid'
                          placeholder='Número máximo de escolhas'
                          min='1'
                          max='250'
                        />
                      </div>
                    </>
                  ) : null}
                </Modal.Body>
              </Form>
              <Modal.Footer>
                <button
                  id='modal-close-button'
                  type='button'
                  className='btn btn-light'
                  onClick={props.handleClose}
                >
                  Fechar
                </button>
                <button type='button' className='btn btn-primary' onClick={() => handleSubmit()}>
                  Salvar
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      ) : null}
    </>
  )
}
