/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { ElectionModel } from '../elections/models/ElectionModel'
import { SessionModel } from '../sessions/models/SessionModel'

type Props = {
  nextSessions: SessionModel[]
  startedElection: ElectionModel | undefined
  startedSession: SessionModel | undefined
}

const DashboardUserVote: React.FC<Props> = ({ nextSessions, startedElection, startedSession }) => {
  return (
    <>
      {!startedElection && !startedSession && nextSessions.length === 0 ? (
        <>
          <p className='text-center fs-1'>Não há Sessão Programada</p>
        </>
      ) : null}
      <div className="d-flex col-12 justify-content-center">
        {startedSession ? (
          <>
            <a href='/dashboard/vote' style={{ color: 'inherit' }} className='px-10 col-6'>
              <p className='text-center'>
                <i className='fas fa-list' style={{ fontSize: '80px', color: 'red' }}></i>
              </p>
              <p className='text-center fs-2'>
                Entrar na Sessão
              </p>
            </a>
          </>
        ) : null}
        {startedElection ? (
          <>
            <a href='/dashboard/election' style={{ color: 'inherit' }} className='px-10 col-6'>
              <p className='text-center'>
                <i className='fa fa-check-square' style={{ fontSize: '80px', color: 'red' }}></i>
              </p>
              <p className='text-center fs-2'>
                Votar na Eleição
              </p>
            </a>
          </>
        ) : null}
      </div>
    </>
  )
}

export { DashboardUserVote }

