import { Field, Form, Formik } from 'formik'
import { Modal } from 'react-bootstrap-v5'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { SessionModel } from './models/SessionModel'
import * as actions from './redux/sessionsActions'

export interface SessionFormProps {
  obj: SessionModel
  handleClose: () => void
}

export function SessionEditForm(props: SessionFormProps) {
  const SessionEditSchema = Yup.object().shape({
    name: Yup.string().required('Descrição é obrigatório.'),
    type: Yup.string().required('Tipo é obrigatório.'),
    date: Yup.date().required('Data é obrigatório.'),
  })
  const dispatch: any = useDispatch()

  const saveSession = (obj: any) => {
    if (!obj.uuid) {
      dispatch(actions.createSession(obj)).then(() => {
        props.handleClose()
      })
    } else {
      dispatch(actions.updateSession(obj)).then(() => {
        props.handleClose()
      })
    }
  }

  return (
    <>
      {props.obj ? (
        <Formik
          enableReinitialize={true}
          initialValues={props.obj}
          validationSchema={SessionEditSchema}
          onSubmit={(values) => {
            saveSession(values)
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <>
              <Form className='form form-label-right'>
                <Modal.Body>
                  <div className='mb-10' style={{ textAlign: 'left' }}>
                    <label className='form-label'>Descrição</label>
                    <Field
                      type='text'
                      name='name'
                      className='form-control form-control-solid'
                      placeholder='Descrição'
                    />
                    {errors.name && touched.name ? (
                      <span style={{ color: 'red' }}>{errors.name}</span>
                    ) : null}
                  </div>
                  <div className='mb-10' style={{ textAlign: 'left' }}>
                    <label className='form-label'>Data</label>
                    <Field
                      type='date'
                      name='date'
                      className='form-control form-control-solid'
                      placeholder='Data'
                    />
                    {errors.date && touched.date ? (
                      <span style={{ color: 'red' }}>{errors.date}</span>
                    ) : null}
                  </div>
                  <div className='mb-10' style={{ textAlign: 'left' }}>
                    <label className='form-label'>Tipo</label>
                    <Field
                      as='select'
                      name='type'
                      className='form-select form-select-solid'
                      aria-label='Select example'
                      placeholder='Tipo'
                    >
                      <option>Selecione...</option>
                      <option value='Ordinária'>Ordinária</option>
                      <option value='Extraordinária'>Extraordinária</option>
                    </Field>
                    {errors.type && touched.type ? (
                      <span style={{ color: 'red' }}>{errors.type}</span>
                    ) : null}
                  </div>
                </Modal.Body>
              </Form>
              <Modal.Footer>
                <button
                  id='modal-close-button'
                  type='button'
                  className='btn btn-light'
                  onClick={props.handleClose}
                >
                  Fechar
                </button>
                <button type='button' className='btn btn-primary' onClick={() => handleSubmit()}>
                  Salvar
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      ) : null}
    </>
  )
}
