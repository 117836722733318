import { Field, Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { UserModel } from './models/UserModel'
import * as actions from './redux/usersActions'

export interface UserFormProps {
  obj: UserModel
  handleClose: () => void
}

export function UserEditForm(props: UserFormProps) {
  const UserEditSchema = Yup.object().shape({
    cpf: Yup.number().required('CPF é óbrigatório.'),
    complete_name: Yup.string().optional(),
    simple_name: Yup.string().required('Nome simples é obrigatório.'),
    email: Yup.string().email().required('E-mail é obrigatório.'),
    profile: Yup.string().required('Perfil é obrigatório.'),
  })
  const UserCreateSchema = Yup.object().shape({
    cpf: Yup.number().required('CPF é óbrigatório.'),
    complete_name: Yup.string().optional(),
    simple_name: Yup.string().required('Nome simples é obrigatório.'),
    email: Yup.string().email().required('E-mail é obrigatório.'),
    profile: Yup.string().required('Perfil é obrigatório.'),
    password: Yup.string().required('Senha é obrigatória.'),
    confirmPassword: Yup.string().when('password', {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Campos não coincidem'),
    }),
  })

  const dispatch: any = useDispatch()

  const saveUser = (obj: UserModel) => {
    if (!obj.uuid) {
      dispatch(actions.createUser(obj)).then(() => {
        props.handleClose()
      })
    } else {
      dispatch(actions.updateUser(obj)).then(() => {
        props.handleClose()
      })
    }
  }

  return (
    <>
      {props.obj ? (
        <Formik
          enableReinitialize={true}
          initialValues={props.obj}
          validationSchema={props.obj.uuid ? UserEditSchema : UserCreateSchema}
          onSubmit={(values) => {
            saveUser(values)
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
            <>
              <Form className='form form-label-right'>
                <div className='modal-body'>
                  <div className='mb-10' style={{ textAlign: 'left' }}>
                    <label className='form-label'>CPF</label>
                    <Field
                      name='cpf'
                      className='form-control form-control-solid'
                      placeholder='CPF'
                    />
                    {errors.cpf && touched.cpf ? (
                      <span style={{ color: 'red' }}>{errors.cpf}</span>
                    ) : null}
                  </div>
                  <div className='mb-10' style={{ textAlign: 'left' }}>
                    <label className='form-label'>Nome completo</label>
                    <Field
                      name='complete_name'
                      className='form-control form-control-solid'
                      placeholder='Nome completo'
                    />
                  </div>
                  <div className='mb-10' style={{ textAlign: 'left' }}>
                    <label className='form-label'>Nome simples</label>
                    <Field
                      name='simple_name'
                      className='form-control form-control-solid'
                      placeholder='Nome simples'
                    />
                    {errors.simple_name && touched.simple_name ? (
                      <span style={{ color: 'red' }}>{errors.simple_name}</span>
                    ) : null}
                  </div>
                  <div className='mb-10' style={{ textAlign: 'left' }}>
                    <label className='form-label'>E-mail</label>
                    <Field
                      name='email'
                      type='email'
                      className='form-control form-control-solid'
                      placeholder='Email'
                    />
                    {errors.email && touched.email ? (
                      <span style={{ color: 'red' }}>{errors.email}</span>
                    ) : null}
                  </div>
                  <div className='mb-10' style={{ textAlign: 'left' }}>
                    <label className='form-label'>Perfil</label>
                    <Field
                      as='select'
                      name='profile'
                      className='form-select form-select-solid'
                      aria-label='Select example'
                      placeholder='Perfil'
                    >
                      <option>Selecione...</option>
                      <option value='User'>Associado</option>
                      <option value='Administrator'>Administrador</option>
                      <option value='Member'>Membro Comissão Eleitoral</option>
                    </Field>
                    {errors.profile && touched.profile ? (
                      <span style={{ color: 'red' }}>{errors.profile}</span>
                    ) : null}
                  </div>
                  {!props.obj.uuid ? (
                    <>
                      <div className='mb-10' style={{ textAlign: 'left' }}>
                        <label className='form-label'>Senha</label>
                        <Field
                          type='password'
                          autoComplete='off'
                          name='password'
                          className='form-control form-control-solid'
                          placeholder='Senha'
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {errors.password && touched.password ? (
                          <span style={{ color: 'red' }}>{errors.password}</span>
                        ) : null}
                      </div>
                      <div className='mb-10' style={{ textAlign: 'left' }}>
                        <label className='form-label'>Confirme a Senha</label>
                        <Field
                          type='password'
                          autoComplete='off'
                          name='confirmPassword'
                          className='form-control form-control-solid'
                          placeholder='Confirme a Senha'
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <span style={{ color: 'red' }}>{errors.confirmPassword}</span>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              </Form>
              <div className='modal-footer'>
                <button
                  id='modal-close-button'
                  type='button'
                  className='btn btn-light'
                  onClick={props.handleClose}
                >
                  Fechar
                </button>
                <button type='button' className='btn btn-primary' onClick={() => handleSubmit()}>
                  Salvar
                </button>
              </div>
            </>
          )}
        </Formik>
      ) : null}
    </>
  )
}
