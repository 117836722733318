import React from 'react'
import { ElectionModel } from '../../elections/models/ElectionModel'

type SimpleVoteProps = {
    myChoice: any
    startedElection: ElectionModel
    setMyChoice: (item: any) => void
    createVote: () => void
}

const SimpleVote = (props: SimpleVoteProps) => {
    return (
        <div className="w-100 text-center">
            {!props.myChoice ?
                (
                    props.startedElection?.options.map((item) => (
                        <button
                            type='button'
                            onClick={() => props.setMyChoice(item)}
                            className='btn btn-primary mt-2 mx-2'
                        >
                            {item.name}
                        </button>
                    ))
                ) : (
                    <div>
                        <p style={{ fontSize: 20, marginBottom: 10 }}>
                            <span>Confirmar voto em </span><span className="fw-bolder">{props.myChoice.name}</span><span>?</span>
                        </p>
                        <div>
                            <button
                                type='button'
                                onClick={props.createVote}
                                className='btn btn-primary mt-2 mx-2'
                            >
                                Confirmar
                            </button>
                            <button
                                type='button'
                                onClick={() => props.setMyChoice(undefined)}
                                className='btn btn-danger mt-2 mx-2'
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default SimpleVote
