/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {UserMeChangePasswordDialog} from '../../../../app/modules/users/UserMeChangePasswordDialog'
import {RootState} from '../../../../setup'

const HeaderUserMenu: FC = () => {
  const [userUuid, setUserUuId] = useState('')
  const [showChangePassword, setShowChangePassword] = useState(false)
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px'></div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>{user.simple_name}</div>
              <div className='d-flex align-items-center'>{user.email}</div>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item my-1 px-1'>
          <a
            href='#'
            className='menu-link px-5'
            onClick={() => {
              setUserUuId('')
              setShowChangePassword(true)
            }}
          >
            Alterar senha
          </a>
        </div>

        <div className='menu-item px-1'>
          <Link to='/logout' className='menu-link px-5'>
            Sair
          </Link>
        </div>
      </div>

      <UserMeChangePasswordDialog
        show={showChangePassword}
        handleClose={() => setShowChangePassword(false)}
      />
    </>
  )
}

export {HeaderUserMenu}
