import axios from 'axios'
import {TopicModel} from '../models/TopicModel'

const API_URL = process.env.REACT_APP_API_URL || 'https://api.assempece.org.br/api/v1'

export const LIST_TOPICS_URL = `${API_URL}/topics`
export const TOPIC_URL = `${API_URL}/topic`

export function listTopics(queryParams: any) {
  return axios.post(LIST_TOPICS_URL, queryParams)
}

export function find() {
  return axios.get(`${TOPIC_URL}/find`)
}

export function getTopic(uuid: string) {
  return axios.get(`${TOPIC_URL}/${uuid}`)
}

export function createTopic(topic: TopicModel) {
  return axios.post(TOPIC_URL, topic)
}

export function updateTopic(topic: TopicModel) {
  return axios.put(`${TOPIC_URL}/${topic.uuid}`, topic)
}

export function deleteTopic(uuid: string) {
  return axios.delete(`${TOPIC_URL}/${uuid}`)
}

export function changeStatus(topic: TopicModel) {
  return axios.put(`${TOPIC_URL}/changeStatus/${topic.uuid}`, topic)
}

export function printPDF(uuid: string) {
  return axios.get(`${TOPIC_URL}/printPDF/${uuid}`)
}

// ENUMS

export function getInitialStatus() {
  return axios.get(`${TOPIC_URL}/initialStatus`)
}

export function getAfterOpenStatus() {
  return axios.get(`${TOPIC_URL}/afterOpenStatus`)
}

export function getAfterStartStatus() {
  return axios.get(`${TOPIC_URL}/afterStartStatus`)
}
