import {Modal} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import * as actions from './redux/usersActions'

export interface UserEditProps {
  uuid: string
  show: boolean
  handleClose: () => void
}

export function UserDeleteDialog(props: UserEditProps) {
  const dispatch: any = useDispatch()

  const deleteUser = () => {
    dispatch(actions.deleteUser(props.uuid)).then(() => {
      props.handleClose()
    })
  }

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>Exclusão de usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza que deseja excluir esse usuário?</p>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-light' onClick={props.handleClose}>
            Fechar
          </button>
          <button type='button' className='btn btn-danger' onClick={deleteUser}>
            Confirmar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
