import { Modal } from 'react-bootstrap-v5'
import { useDispatch } from 'react-redux'
import * as actions from './redux/electionsActions'

export interface ElectionEditProps {
  uuid: string
  show: boolean
  handleClose: () => void
}

export function ElectionDeleteDialog(props: ElectionEditProps) {
  const dispatch: any = useDispatch()

  const deleteElection = () => {
    dispatch(actions.deleteElection(props.uuid)).then(() => {
      props.handleClose()
    })
  }

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header >
          <Modal.Title>Exclusão de pauta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza que deseja excluir essa pauta?</p>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-light' onClick={props.handleClose}>
            Fechar
          </button>
          <button type='button' className='btn btn-primary' onClick={deleteElection}>
            Confirmar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
