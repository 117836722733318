import axios from 'axios'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'https://api.assempece.org.br/api/v1'

export const LIST_SESSIONS_URL = `${API_URL}/users`
export const SESSION_URL = `${API_URL}/user`

export function listUsers(queryParams: any) {
  return axios.post(LIST_SESSIONS_URL, queryParams)
}

export function getUser(uuid: string) {
  return axios.get(`${SESSION_URL}/${uuid}`)
}

export function createUser(user: UserModel) {
  return axios.post(SESSION_URL, user)
}

export function updateUser(user: UserModel) {
  return axios.put(`${SESSION_URL}/${user.uuid}`, user)
}

export function deleteUser(uuid: string) {
  return axios.delete(`${SESSION_URL}/${uuid}`)
}

export function changePasswordUser(uuid: string, password: string) {
  return axios.post(`${SESSION_URL}/passwordChange`, {
    uuid: uuid,
    password: password,
  })
}
