import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../../../app/modules/users/models/UserModel'
import { UserProfile } from '../../../../app/utils/UserProfile'
import { RootState } from '../../../../setup'
import { MenuItem } from './MenuItem'

export function MenuInner() {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <MenuItem title='Início' to='/dashboard' />
      {
        user?.profile === UserProfile.ADMIN.value || user?.profile === UserProfile.MEMBER.value ?
          (
            <>
              <MenuItem title='Eleições' to='/elections' />
              {
                user?.profile === UserProfile.ADMIN.value ?
                  (
                    <>
                      <MenuItem title='Sessões' to='/sessions' />
                      <MenuItem title='Usuários' to='/users' />
                    </>
                  ) : null
              }
            </>
          ) : null
      }
    </>
  )
}
