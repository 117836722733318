import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { VoteElection } from '../modules/vote/VoteElection'
import { VoteTopic } from '../modules/vote/VoteTopic'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { ElectionWrapper } from '../pages/ElectionWrapper'
import { SessionWrapper } from '../pages/SessionWrapper'
import { TopicWrapper } from '../pages/TopicWrapper'
import { UserWrapper } from '../pages/UserWrapper'

export function PrivateRoutes() {
  /* const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage')) */

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' exact component={DashboardWrapper} />
        <Route path='/dashboard/vote' component={VoteTopic} />
        <Route path='/dashboard/election' component={VoteElection} />
        <Route path='/sessions' exact component={SessionWrapper} />
        <Route path={`/sessions/topic`} component={TopicWrapper} />
        <Route path='/elections' component={ElectionWrapper} />
        <Route path='/users' component={UserWrapper} />
        {/* <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} /> */}
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
