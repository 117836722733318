/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { SessionModel } from '../sessions/models/SessionModel'

type Props = {
  nextSessions: SessionModel[]
}

const DashboardAdmin: React.FC<Props> = ({ nextSessions }) => {
  return (
    <>
      <div className='text-gray-700 fw-bolder text-hover-primary fs-1 pt-2 align-center text-center'>
        Próximas Sessões
      </div>
      {nextSessions.length > 0 ? (
        <div className=''>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-2 gy-3 table-striped'>
              <thead className='border-gray-300'>
                <tr className='fw-bolder text-muted border-gray-400'>
                  <th className='min-w-250px'>Data</th>
                  <th className='min-w-400px'>Descrição</th>
                  <th className='min-w-150px'>Tipo</th>
                </tr>
              </thead>
              <tbody className='border-gray-300'>
                {nextSessions
                  ? nextSessions.map((item: SessionModel) => (
                    <tr key={item.uuid}>
                      <td className='text-dark fw-bolder text-hover-primary fs-6'>
                        {item?.date}
                        {/* <FormatDate date={item?.date} /> */}
                      </td>
                      <td className='text-dark fw-bolder text-hover-primary fs-6'>{item?.name}</td>
                      <td className='text-dark fw-bolder text-hover-primary fs-6'>{item?.type}</td>
                    </tr>
                  ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <p className="fs-1 text-center">Não há Sessão Programada</p>
      )}
    </>
  )
}

export { DashboardAdmin }
