export let TimeToVote = {
  NO_TIME: {text: 'Sem limite de tempo', value: undefined},
  ONE_MINUTE: {text: '1 minuto', value: 60},
  TWO_MINUTES: {text: '2 minutos', value: 120},
  FIVE_MINUTES: {text: '5 minutos', value: 300},
  TEN_MINUTES: {text: '10 minutos', value: 600},
  THIRTY_MINUTES: {text: '30 minutos', value: 1800},
  ONE_HOUR: {text: '1 hora', value: 3600},
  TWO_HOURS: {text: '2 horas', value: 7200},
  SIX_HOURS: {text: '6 horas', value: 21600},
  EIGHT_HOURS: {text: '8 horas', value: 28800},
  NINE_HOURS: {text: '9 horas', value: 32400},
  TWELVE_HOURS: {text: '12 horas', value: 43200},
  ONE_DAY: {text: '1 dia', value: 86400},
}
